import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalCloseButton, ModalContent, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const RightClickModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Atenção
                </Text>
                <ModalBody>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Cópia de conteúdo não autorizada. Proteja seus direitos e respeite o trabalho dos outros.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
