import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import Confetti from 'react-confetti';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Box,
    Text
} from '@chakra-ui/react';
import { colors } from '../styles/colors';
import { GhostButton, YellowButton } from './button';
import imgConfetti from '../assets/icons/confetti.png';
import { useNavigate } from 'react-router-dom';

export const CongratsModal = ({ isOpen, onClose, formData, setFormData, setStep, setWillUseSameData, setResetKey, setResetCategorias }) => {
    const [setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [formDataObj, setFormDataObj] = useState({
        title: '',
        tags: [],
        description: '',
        categories: [],
        peopleRecog: false,
        acceptTerms: false,
        camera: '',
        location: '',
        price: 0,
        gPSLat: '',
        gPSLong: '',
        docFile: '',
        errors: {
            title: '',
            tags: '',
            description: '',
            categories: '',
            acceptTerms: '',
            location: '',
            lat: '',
            long: '',
        }
    });

    const navigate = useNavigate();
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCopyFormData = () => {
        if (formData != null) {
            var storage = JSON.parse(localStorage.getItem('@formDataVideo'));
            if (storage == null) {
                localStorage.removeItem('@formDataVideo');
                localStorage.setItem('@formDataVideo', JSON.stringify(formData));
            }
            else {
                localStorage.setItem('@formDataVideo', JSON.stringify(formData));
            }
        }
        setStep(0);
        setWillUseSameData(true);
        onClose();
    }

    const handleResetFormData = () => {
        var storage = JSON.parse(localStorage.getItem('@formDataVideo'));
        if (storage != null) {
            localStorage.removeItem('@formDataVideo');
        }
        setFormData(formDataObj);
        setStep(0);
        setWillUseSameData(false);
        setResetKey(prevKey => prevKey + 1);
        setResetCategorias(true);
        onClose();
    }

    return (
        <div className="task-container">
            <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered closeOnOverlayClick={false}>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(2px)' />
                <ModalContent backgroundColor={colors.background} color={colors.white}>
                    <ModalHeader></ModalHeader>
                    <ModalBody px={'4rem'}>
                        <Box textAlign={'center'} lineHeight={'50px'}>
                            <Box textAlign={'center'} display={'flex'} justifyContent={'center'}>
                                <img src={imgConfetti} alt="confetti" style={{ width: '60%' }} />
                            </Box>
                            <Text fontWeight="bold" color={'#FFF'} fontSize={'4.5rem'}>Muito bem!</Text>
                            <Text fontWeight="bold" color={'#FFF'} fontSize={'1.5rem'}>Você fez o upload com sucesso.</Text>
                            <Text fontWeight="bold" color={'#FFCC00'} fontSize='sm'>Título do vídeo: {formData?.title}</Text>
                            <Text fontWeight="bold" color={'#FFF'} fontSize={'1.2rem'}>
                                O que você deseja fazer agora?
                            </Text>
                            <Box fontSize="sm" mt={'0.8rem'}>
                                <YellowButton text="Fazer um novo upload com os mesmos dados" w="full" size="lg" borderRadius={5} onClick={() => handleCopyFormData()} />
                                <GhostButton text="Fazer um novo upload do inicio" w="full" size="lg" borderRadius={5} onClick={() => handleResetFormData()} />
                                <GhostButton text="Voltar para a home" w="full" size="lg" borderRadius={5} onClick={() => navigate("/")} />
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};
