export const colors = {
    primary: "#FFCC00",
    secondary: "#abc617",
    background: "#1C1C1C",
    white: "#FFFFFF",
    white75: "#ffffffc9",
    white50: "#ffffff60",
    whiteTransparent: "#ffffff21",
    whiteTransparent2: "#ffffff05",
    gray: "#A9A9A9",
    grayInput: "#4D4D4D",
    grayInput75: "#4D4D4D75",
    grayBack: "#292929",
    border: "#717171",
    green: "#02BD6D",
    red: '#E53E3E',
    greenDark: "#014F2E",
    black: "#000000",
    blackGroup: "#0F0F0F",
    blackBorder: "#ffffff23",
    purple: "#9747FF",
    yellow: {
        200: '#FFCC00',
    },
};
