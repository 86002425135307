
import { Badge, Text } from '@chakra-ui/react';
import React from 'react';

export const CustomTag = ({ text, ...rest }) => {
    return (
        <Badge fontWeight={"400"} background={"transparent"} borderRadius={5} p={'2.5px'} borderWidth={1} {...rest}>
            <Text fontSize="2xs">{text}</Text>
        </Badge>
    );
};