import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalCloseButton, ModalContent, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import YouTube from 'react-youtube';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const HowItWorksVideo = ({ isOpen, onClose, videoUrl }) => {
    const videoId = new URL(videoUrl).searchParams.get("v");

    const opts = {
        height: '450',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.black} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Como Funciona?
                </Text>
                <ModalBody>
                    <YouTube videoId={videoId} opts={opts} />
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};