import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalCloseButton, ModalContent, ModalOverlay, Text, VStack, Box, HStack } from '@chakra-ui/react';
import { YellowButton, IconGhostButton } from './button';
import { colors } from '../styles/colors';
import { FaDownload } from 'react-icons/fa';
import Api from '../services/api';

const downloadImageRightsTemplate = async (type) => {
    try {
        const result = await Api.get(`/video/downloadImageRightsTemplate/${type}`, { responseType: 'blob' });
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${type}.docx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error("Erro ao baixar o template de direitos de imagem: ", error);
    }
};

export const DocTemplateModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Modelos de Termos de Autorização de Imagem
                </Text>
                <ModalBody pb={4} pt={4}>
                    <VStack spacing={3} align="stretch">
                        <HStack justifyContent="space-evenly">
                            <Text fontSize="md" color={colors.white75}>Modelo para Maiores de Idade</Text>
                            <IconGhostButton
                                onClick={() => downloadImageRightsTemplate('maiores')}
                                size="xs"
                                icon={<FaDownload color={colors.white75} />}
                                borderWidth={1}
                                borderColor={colors.white75}
                            />
                        </HStack>
                        <HStack justifyContent="space-evenly">
                            <Text fontSize="md" color={colors.white75}>Modelo para Menores de Idade</Text>
                            <IconGhostButton
                                onClick={() => downloadImageRightsTemplate('menores')}
                                size="xs"
                                icon={<FaDownload color={colors.white75} />}
                                borderWidth={1}
                                borderColor={colors.white75}
                            />
                        </HStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
