import React, { useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalFooter, ModalCloseButton, ModalContent, ModalOverlay, Text, VStack, Box } from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const VideoModal = ({ isOpen, onClose, video }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (isOpen && videoRef.current) {
            const videoElement = videoRef.current;

            const tryPlayVideo = (attempts = 3) => {
                if (attempts <= 0) {
                    console.error('Falha ao reproduzir o vídeo após várias tentativas');
                    return;
                }

                const playPromise = videoElement.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        console.log('Vídeo está sendo reproduzido');
                    }).catch(error => {
                        console.error(`Falha na reprodução do vídeo (tentativa ${4 - attempts}):`, error);
                        if (error.name === 'NotAllowedError' || error.name === 'NotSupportedError') {
                            setTimeout(() => tryPlayVideo(attempts - 1), 500);
                        }
                    });
                }
            };

            videoElement.load(); // Garante que o vídeo seja recarregado
            tryPlayVideo();
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <ModalBody>
                    <Box mb={4}>
                        <Text fontSize="xl" color={colors.white75}>{video.title}</Text>
                        <video
                            ref={videoRef}
                            src={video.videoUrl}
                            controls
                            style={{ width: '100%', height: 'auto', marginTop: '1rem' }}
                        />
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};