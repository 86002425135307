import axios from 'axios';

const Api = axios.create({
    //baseURL: 'https://haustri.com'
    baseURL: 'https://api.haustri.com'
    //baseURL: 'http://192.168.18.9:3333',
    //baseURL: 'http://localhost:3333'
});

export const exceptionNotificationAPI = (error, toast) => {
    if (/500/.test(error.message)) {
        console.error('Failed to connect Web Service (500).');
    } else if (/404/.test(error.message)) {
        console.error('Route Not Found (404).');
    } else if (/401/.test(error.message)) {
        sessionStorage.clear();
        localStorage.removeItem('@HaustriApp:token');
        localStorage.removeItem('@HaustriApp:user');
        localStorage.removeItem('@HaustriApp:userName');

        window.location.href = "/";
    } else if (/400/.test(error.message)) {
        let notifications = error.response.data.errors;
        if (notifications && notifications.length > 0) {
            notifications.forEach((not) => {
                console.log(not);
            })
        }
    } else {
        console.error(error.message);
    }
}

export default Api;
