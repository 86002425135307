import { Box, Container, Flex, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GhostButton } from '../../components/button';
import { Footer } from '../../components/footer';
import Header from '../../components/header';
import HorizontalScrollingText from '../../components/horizontalScrollingText';
import { CustomFormInput, CustomSelect2 } from '../../components/input';
import { Videos } from '../../components/videos';
import Api from '../../services/api';
import { Loading } from '../../components/loading'; // Importação do componente de loading
import { colors } from '../../styles/colors';

const Products = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [allFilters, setAllFilters] = useState(null);
    const [allCategories, setAllCategories] = useState(null);

    const [selectedValues, setSelectedValues] = useState({
        text: state ? state?.text ?? "" : "",
        categories: null,
        proportion: null,
        state: null,
        resolution: null,
        relevance: null
    });

    const [videos, setVideos] = useState([]);
    const [totalVideos, setTotalVideos] = useState(0);
    const [page, setPage] = useState(1);
    const [itens, setItens] = useState(24);
    const [loading, setLoading] = useState(false); // Estado de loading
    const [isRequestPending, setIsRequestPending] = useState(false); // Flag para evitar requisições duplicadas

    useEffect(() => {
        async function load() {
            setLoading(true); // Mostrar loading enquanto os filtros e categorias são carregados
            try {
                const videoFilters = await Api.get('/video/filters');
                setAllFilters(videoFilters.data);

                const categories = await Api.get('/categories');
                const categoriesArray = Object.values(categories.data); // Converte o objeto para array
                setAllCategories(categoriesArray);

                await getVideos();
            } catch (error) {
                console.error("Erro ao carregar filtros e categorias:", error);
            } finally {
                setLoading(false); // Ocultar loading
            }
        }
        load();
    }, []);

    // Função para buscar os vídeos
    const getVideos = async () => {
        // Evitar requisições duplicadas
        if (isRequestPending) return;

        try {
            setIsRequestPending(true); // Marcar que a requisição está em andamento
            setLoading(true); // Mostrar loading
            const [getAllRes] = await Promise.all([
                Api.post('/video/filters', {
                    "page": page,
                    "limitPerPage": itens,
                    "text": selectedValues.text,
                    "categoriesId": selectedValues.categories?.map(x => x.value),
                    "proportion": selectedValues.proportion?.map(x => x.value),
                    "state": selectedValues.state?.map(x => x.value),
                    "resolution": selectedValues.resolution && selectedValues.resolution.length > 0 ? selectedValues.resolution : null,
                    "relevance": selectedValues.relevance?.value,
                    "randomOrder": false,
                })
            ]);
            const getAll = getAllRes.data.videos.map(x => ({
                id: x.idVideo,
                title: x.titleScene,
                description: x.descriptionScene,
                price: x.price,
                isNews: x.isNews,
                commissionType: x.idCommissionValueType,
                thumbnailUrl: x.thumbMomentImgLink,
                videoUrl: x.videoLinkCompressedAndWatermark,
                city: x.city,
                local: x.local,
                state: x.state,
                displayName: x.displayName,
                fullName: x.fullName,
                resolution: x.resolution,
                duration: x.duration,
                isFavorite: x.isFavorite,
                totalDownloads: x.totalDownloads,
                categories: x.categories.map(cat => ({ id: cat.idCategories, description: cat.description })),
                tags: x.tags.map(tag => ({ id: tag.IdTags, description: tag.Description })),
                onClick: () => navigate("/produto/" + x.idVideo)
            }));
            setVideos(getAll);
            setTotalVideos(getAllRes.data.totalVideos);
        } catch (error) {
            console.error("Erro ao buscar vídeos:", error);
        } finally {
            setIsRequestPending(false); // Liberar para novas requisições
            setLoading(false); // Ocultar loading
        }
    };

    useEffect(() => {
        getVideos();
    }, [page]);

    useEffect(() => {
        setPage(1);
        getVideos();
    }, [selectedValues]);

    return (
        <>
            <Header />

            <Container maxW="container.xl" position="relative" mb={20}>
                <Flex justify="space-between" align="center" my={10}>
                    <VStack align="flex-start" gap={0}>
                        <Box mt={7} display="flex" flexWrap="wrap" color={colors.white50} fontSize="sm">
                            <Text>Explore o conteúdo</Text>
                        </Box>
                        <Text fontSize="2xl" color={colors.white75}>Delimite sua busca para encontrar resultados</Text>
                    </VStack>
                </Flex>
                <Flex justify="space-between" align="center" my={10}>
                    <Grid
                        w="full"
                        templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(6, auto)' }}
                        gap={6}
                        alignItems="flex-end"
                    >
                        <GridItem>
                            <CustomFormInput
                                backgroundColor={colors.black}
                                id="text"
                                label="Procurar por"
                                placeholder="Digite aqui..."
                                values={selectedValues}
                                setValues={setSelectedValues}
                                isDisabled={loading}
                            />
                        </GridItem>

                        <GridItem>
                            <CustomSelect2
                                placeholder="Categoria"
                                isMulti
                                options={allCategories?.sort((a, b) =>
                                    a.description.localeCompare(b.description)
                                ).map(x => ({ value: x.idCategories, label: x.description }))}
                                value={selectedValues.categories}
                                onChange={value =>
                                    setSelectedValues({ ...selectedValues, categories: value })
                                }
                                isDisabled={loading}
                            />
                        </GridItem>

                        <GridItem>
                            <CustomSelect2
                                placeholder="Formato"
                                isMulti
                                options={allFilters?.proportion.sort((a, b) =>
                                    a.localeCompare(b)
                                ).map(x => ({ value: x, label: x }))}
                                value={selectedValues.proportion}
                                onChange={value =>
                                    setSelectedValues({ ...selectedValues, proportion: value })
                                }
                                isDisabled={loading}
                            />
                        </GridItem>

                        <GridItem>
                            <CustomSelect2
                                placeholder="Estado"
                                isMulti
                                options={allFilters?.state.sort((a, b) => (a > b ? 1 : -1)).map(x => ({
                                    value: x,
                                    label: x,
                                }))}
                                value={selectedValues.state}
                                onChange={value =>
                                    setSelectedValues({ ...selectedValues, state: value })
                                }
                                isDisabled={loading}
                            />
                        </GridItem>

                        <GridItem>
                            <CustomSelect2
                                placeholder="Resolução"
                                isMulti
                                options={[
                                    { value: 'HD', label: 'Full HD' },
                                    { value: '4K', label: '4K' },
                                    { value: '8K', label: '8K' },
                                ]}
                                value={selectedValues.resolution}
                                onChange={value =>
                                    setSelectedValues({
                                        ...selectedValues,
                                        resolution: value ? value.map(v => v.value) : null,
                                    })
                                }
                                isDisabled={loading}
                            />
                        </GridItem>

                        <GridItem>
                            <CustomSelect2
                                placeholder="Relevância"
                                options={[
                                    { value: null, label: 'Selecione' },
                                    { value: 'desc', label: 'Mais Recente' },
                                    { value: 'asc', label: 'Menos recente' },
                                ]}
                                value={selectedValues.relevance}
                                onChange={value => {
                                    setSelectedValues({
                                        ...selectedValues,
                                        relevance: value ? value : null,
                                    });
                                }}
                                isDisabled={loading}
                            />
                        </GridItem>
                    </Grid>
                </Flex>

                {loading ? (
                    <Loading />
                ) : (
                    <Videos videos={videos} setVideos={setVideos} />
                )}

                <Flex justify="center" alignItems="center" mt={10}>
                    {page > 1 && <GhostButton borderWidth={1} borderColor={colors.white75} text="Anterior" mr={4} onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))} />}
                    <Text mr={4} fontSize="sm">Página atual: {page} de {Math.ceil(totalVideos / itens)}</Text>
                    {page * itens < totalVideos && <GhostButton borderWidth={1} borderColor={colors.white75} text="Próximo" onClick={() => setPage(prevPage => prevPage + 1)} />}
                </Flex>
            </Container >

            <Box justify="center" align="center">
                {allCategories && <>
                    <HorizontalScrollingText texts={[...allCategories, ...allCategories]} scrollDirection={"rightToLeft"} />
                    <HorizontalScrollingText texts={[...allCategories, ...allCategories]} scrollDirection={"leftToRight"} />
                </>}
            </Box>

            <Footer />
        </>
    );
};

export default Products;