import { Box, Progress, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { colors } from '../styles/colors';

export const LoadingSendVideo = ({ p = 5 }) => {
    const messages = [
        "Aguarde...",
        "Estamos organizando tudo para a sua melhor experiência...",
        "Já estamos quase finalizando..."
    ];

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const messageInterval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 2500); // Troca de mensagem a cada 2.5 segundos

        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => {
                const newProgress = prevProgress + (100 / 70); // 70 segundos para 100%
                return newProgress >= 100 ? 100 : newProgress;
            });
        }, 500); // Atualiza o progresso a cada 0.5 segundo

        return () => {
            clearInterval(messageInterval);
            clearInterval(progressInterval);
        };
    }, []);

    return (
        <Box w="full" h="full" p={p} bg={colors.black} position="fixed" top={0} left={0} zIndex={10}>
            <VStack justifyContent="center" alignContent="center" alignItems="center" w="full" h="full">
                <Text fontSize="xl" color={colors.primary} textAlign="center">
                    {messages[currentMessageIndex]}
                </Text>
                <Box width="80%" mt={5}>
                    <Progress size="sm" value={progress} colorScheme="yellow" />
                </Box>
            </VStack>
        </Box>
    );
};
