import React from 'react';

const Authorized = ({ children }) => {
    return <>
        <div className="main-body">
            <main role="main">{children}</main>
        </div>
    </>;
};

export default Authorized;