import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import UserRegistrationList from '../pages/Admin/userRegistrationList';
import VideoApproval from '../pages/Admin/videoApproval';
import WalletApproval from '../pages/Admin/walletApproval';
import Home from '../pages/Home';
import Products from '../pages/Product';
import ProductUpload from '../pages/Product/upload';
import Product from '../pages/Product/view';
import Account from '../pages/User';
import RouteWrapper from './route';

const RoutesList = () => {
    const ScrollToTop = ({ children }) => {
        const location = useLocation();

        useEffect(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, [location.pathname]);

        return children;
    };

    const router = createBrowserRouter([
        { path: "/", element: <><RouteWrapper element={Home} /><ScrollToTop /></> },
        { path: "/produtos", element: <><RouteWrapper element={Products} /><ScrollToTop /></> },
        { path: "/produto/:id", element: <><RouteWrapper element={Product} /><ScrollToTop /></> },
        { path: "/upload", element: <><RouteWrapper element={ProductUpload} /><ScrollToTop /></> },
        { path: "/conta/:active?", element: <><RouteWrapper element={Account} /><ScrollToTop /></> },
        { path: "/admin/lista", element: <><RouteWrapper element={VideoApproval} admin /><ScrollToTop /></> },
        { path: "/admin/saques", element: <><RouteWrapper element={WalletApproval} admin /><ScrollToTop /></> },
        { path: "/admin/users/list", element: <><RouteWrapper element={UserRegistrationList} admin /><ScrollToTop /></> },
    ]);

    return (
        <RouterProvider router={router} />
    );
};

export default RoutesList;