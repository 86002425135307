import { Avatar, Box, Container, Flex, Grid, GridItem, HStack, IconButton, Image, Slider, SliderFilledTrack, SliderTrack, Spinner, Text, VStack } from '@chakra-ui/react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronRight, FaCompress, FaExpand, FaPause, FaPlay } from 'react-icons/fa';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useNavigate, useParams } from 'react-router-dom';
import { GhostButton, YellowButton } from '../../components/button';
import { Cart } from '../../components/cart';
import { Footer } from '../../components/footer';
import Header from '../../components/header';
import { CustomTag } from '../../components/tag';
import { Videos } from '../../components/videos';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';

const Product = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const mapContainerRef = useRef(null);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePlayPause = (playing) => {
    setIsPlaying(playing);
  };

  const handleTimeUpdate = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      setSliderValue(videoRef.current.currentTime);
    }
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement));
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play().catch(error => console.error('Failed to play the video:', error));
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const [video, setVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videos2, setVideos2] = useState([]);
  const [hasViewBeenRecorded, setHasViewBeenRecorded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;

    async function load() {
      try {
        // Remova setVideo(null); a menos que seja absolutamente necessário
        const response = await Api.get('/video/' + id);
        if (isMounted) {
          setVideo(response.data);

          const getAll = Object.values(response.data.relatedVideos).map(x => ({
            id: x.idVideo,
            videoUrl: x.videoLinkCompressedAndWatermark,
            thumbnailUrl: x.thumbMomentImgLink,
            text: x.highlightTitle,
            description: x.highlightSubTitle,
            state: x.state,
            fullName: x.fullName,
            duration: x.duration,
            price: x.price,
            isFavorite: x.isFavorite,
            onClick: () => navigate("/produto/" + x.idVideo)
          }));

          let videos1 = [];
          let videos2 = [];
          if (getAll.length >= 3) {
            videos1 = getAll.slice(0, 3);
            videos2 = getAll.slice(3, 6);
          } else if (getAll.length > 0) {
            videos1 = getAll.slice(0, getAll.length);
          }
          setVideos(videos1);
          setVideos2(videos2);
        }
      } catch (error) {
        console.error("Erro ao carregar o vídeo:", error);
      }
    }

    load();

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
  if (video && video.idVideo) {
    const timer = setTimeout(() => {
      if (!hasViewBeenRecorded) {
        Api.post('/views', { idVideo: video.idVideo })
          .then(() => {
            setHasViewBeenRecorded(true);
          })
          .catch(error => {});
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }
}, [video?.idVideo]);

  useEffect(() => {
    return () => {
      setHasViewBeenRecorded(false);
      setVideoLoaded(false);
    };
  }, [video]);

  const handleFavorite = (video) => {
    if (video.isFavorite === 0)
      Api.post('/favorites', { idVideo: video.idVideo })
        .then(response => {
          setVideo({ ...video, isFavorite: 1 });
        }).catch(error => {
        });
    else
      Api.delete('/favorites/video/' + video.idVideo)
        .then(response => {
          setVideo({ ...video, isFavorite: 0 });
        }).catch(error => {
        });
  };

  const [cartOpen, setCartOpen] = useState(false);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [errorBuy, setErrorBuy] = useState(null);

  const addToCartAndOpenModal = () => {
    setLoadingBuy(true);
    Api.post('/shoppingcart', { idVideo: video.idVideo })
      .then(response => {
        if (response.data && response.data.id && response.data.id > 0) {
          setCartUpdated(true);
        }
        setLoadingBuy(false);
      })
      .catch(error => {
        try {
          setErrorBuy(error.response.data.message);
        } catch { }
        setLoadingBuy(false);
      });
  };

  useEffect(() => {
    if (cartUpdated) {
      setCartOpen(true);
      setCartUpdated(false);
    }
  }, [cartUpdated]);

  const formatTime = (time) => {
    if (typeof time !== 'number' || isNaN(time)) {
      return "";
    }

    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const tryPlayVideo = (videoElement, attempts = 3) => {
    if (!videoElement) return;

    const playPromise = videoElement.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        console.log("Vídeo reproduzido com sucesso");
      }).catch(error => {
        console.error(`Falha ao reproduzir o vídeo (tentativa ${4 - attempts}):`, error);
        if (attempts > 1) {
          setTimeout(() => {
            const currentVideoElement = videoRef.current;
            if (currentVideoElement) {
              tryPlayVideo(currentVideoElement, attempts - 1);
            }
          }, 500);
        }
      });
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handleEnded = () => setIsPlaying(false);
      const handlePause = () => handlePlayPause(false);
      const handlePlay = () => handlePlayPause(true);
      const handleStalled = () => tryPlayVideo(videoElement);

      videoElement.addEventListener('ended', handleEnded);
      videoElement.addEventListener('pause', handlePause);
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('stalled', handleStalled);

      return () => {
        videoElement.removeEventListener('ended', handleEnded);
        videoElement.removeEventListener('pause', handlePause);
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('stalled', handleStalled);
      };
    }
  }, [video]);

  useEffect(() => {
    if (videoRef.current && isPlaying) {
      tryPlayVideo(videoRef.current);
    }
  }, [video, isPlaying]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header />
        <Cart isOpen={cartOpen} onClose={() => setCartOpen(false)} />
        <div style={{ height: '75vh', paddingBottom: 100 }}>
          <Container
            maxW="container.xl"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="full"
          >
            <VStack align="stretch" />

            <VStack align="stretch">
              <Flex
                maxW="md"
                borderRadius="25px"
                overflow="hidden"
                bg={{
                  base: `${colors.black}87`, // Transparência maior para mobile
                  md: `${colors.black}c9`, // Transparência padrão para telas maiores
                }}
                color="white"
              >
                <Box p="10">
                  <Text as="span" fontSize="xs" color={colors.white75}>
                    {video?.totalDownloads > 99 ? '+99 Downloads' : `${video?.totalDownloads} Downloads`}
                  </Text>

                  <Box mt={5} display="flex" flexWrap="wrap" color={colors.white75} fontSize="xs">
                    {video?.tags?.slice(0, 3).map((tag, index) => (
                      <React.Fragment key={tag.IdTags}>
                        {index > 0 && <Text mt="5px" ml={1} mr={1}><FaChevronRight size={7.5} /></Text>}
                        <Text>{tag.Description}</Text>
                      </React.Fragment>
                    ))}
                    {video?.tags && video.tags.length > 3 && (
                      <CustomTag text={`+${video.tags.length - 3} Tags`} mt={-1} ml={4} />
                    )}
                  </Box>

                  <Text mt={5} fontSize="3xl" fontWeight="semibold" lineHeight="35px">
                    {video?.titleScene}
                  </Text>

                  <Box mt={5}>
                    <Box as="span" fontSize="xl">
                      R$ {video?.price}
                    </Box>
                  </Box>

                  <Box mt={5} display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" maxW="60%">
                      <Box py={2}>
                        <Image src={require("../../assets/icons/map.png")} w="25px" />
                      </Box>
                      <Box display="flex" flexDirection="column" ml={2}>
                        <Box as="span" color={colors.white75} fontSize="xs">
                          Feito em
                        </Box>
                        <Box as="span" color={colors.white75} fontSize="sm">
                          {video?.city} - {video?.state}
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" ml={2} maxW="40%">
                      <Box py={2}>
                        <Image src={require("../../assets/icons/res.png")} w="20px" />
                      </Box>
                      <Box display="flex" flexDirection="column" ml={2}>
                        <Box as="span" color={colors.white75} fontSize="xs">
                          Resolução
                        </Box>
                        <Box as="span" color={colors.white75} fontSize="sm">
                          {video?.resolution}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <HStack mt={10} spacing="4" justifyContent="space-between">
                    {!user && (
                      <Text fontSize="xl" fontWeight="semibold" lineHeight="35px">
                        Entre para comprar
                      </Text>
                    )}
                    {user && (
                      <>
                        {errorBuy ? (
                          <Text fontSize="sm">{errorBuy}</Text>
                        ) : loadingBuy ? (
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor={colors.background}
                            color={colors.primary}
                            size="md"
                          />
                        ) : (
                          <YellowButton text="Comprar Video" onClick={addToCartAndOpenModal} />
                        )}
                        <GhostButton
                          onClick={() => handleFavorite(video)}
                          icon={
                            <Image
                              src={require(
                                video?.isFavorite === 1
                                  ? "../../assets/icons/heartpink.png"
                                  : "../../assets/icons/heart.png"
                              )}
                              w="15px"
                            />
                          }
                          text={
                            video?.isFavorite === 1
                              ? "Remover da Coleção"
                              : "Salvar na Coleção"
                          }
                        />
                      </>
                    )}
                  </HStack>
                </Box>
              </Flex>
            </VStack>
          </Container>

          {video && (
            <>
              <video
                ref={videoRef}
                autoPlay={isPlaying}
                width="100%"
                muted
                onTimeUpdate={handleTimeUpdate}
                onPause={() => handlePlayPause(false)}
                onPlay={() => handlePlayPause(true)}
                onError={() => tryPlayVideo(videoRef.current)}
                style={{
                  position: 'absolute',
                  width: '100%',
                  objectFit: 'cover',
                  height: '100%',
                  top: 0,
                  left: 0,
                  zIndex: -1,
                  filter: 'brightness(90%)'
                }}
              >
                <source src={video?.videoLinkCompressedAndWatermark} type="video/mp4" />
                Seu navegador não suporta vídeo HTML5.
              </video>
              <Flex
                position="absolute"
                bottom="50px"
                left="50%"
                transform="translateX(-50%)"
                zIndex="2"
                alignItems="center"
                gap="10px"
                flexWrap={{ base: 'wrap', md: 'nowrap' }} // Permite quebra de linha no mobile
                width="90%" // Ajusta a largura total dos controles
              >
                <IconButton
                  backgroundColor="transparent"
                  onClick={() => handlePlayPause(!isPlaying)}
                  icon={isPlaying ? <FaPause /> : <FaPlay />}
                />
                <Slider
                  flex="1"
                  min={0}
                  max={videoRef?.current?.duration || 0}
                  colorScheme="yellow"
                  value={sliderValue}
                  onChange={(value) => {
                    if (videoRef.current) {
                      videoRef.current.currentTime = value;
                      setSliderValue(value);
                    }
                  }}
                >
                  <SliderTrack>
                    <SliderFilledTrack transition="all 0.25s ease-in-out" />
                  </SliderTrack>
                </Slider>
                <Box display="flex" alignItems="center">
                  <Text>{formatTime(currentTime)}</Text>
                  <Text as="span">&nbsp;/&nbsp;</Text>
                  <Text>{formatTime(videoRef?.current?.duration)}</Text>
                </Box>
                <IconButton
                  backgroundColor="transparent"
                  onClick={toggleFullScreen}
                  icon={isFullScreen ? <FaCompress /> : <FaExpand />}
                />
              </Flex>
            </>
          )}
        </div>
      </div>

      <div style={{ marginTop: -300, height: 300, backgroundImage: `linear-gradient(to bottom, transparent, ${colors.black})` }}>
      </div>

      <Container maxW="container.xl" mb={20}>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={6}
        >
          <Box p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
            <Text fontSize="sm">Detalhamento de cena</Text>
            <Text fontSize="xs" color={colors.white50} mb={2}>Descrição e cena do vídeo</Text>

            <Text mt={5} fontSize="xs" color={colors.white75} mb={2}>{video?.descriptionScene}</Text>
            <Flex mt={5} wrap="wrap" gap={2}>
              {video?.categories?.map((category) => (
                <CustomTag key={category.IdCategories} text={category.Description} />
              ))}
            </Flex>

          </Box>

          <Box p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
            <Text fontSize="sm">Características Técnicas</Text>
            <Text fontSize="xs" color={colors.white50} mb={2}>Informações Técnicas da Composição</Text>

            <Grid templateColumns='repeat(3, 1fr)' mt={5} gap={5}>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Código do ativo</Text>
                <Text fontSize="xs">{video?.idVideo}</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Resolução</Text>
                <Text fontSize="sm">{video?.resolution}</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>FPS</Text>
                <Text fontSize="sm">{video?.fPS}</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Duração</Text>
                <Text fontSize="xs">{video?.duration} segundos</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Proporção</Text>
                <Text fontSize="sm">{video?.proportion}</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Câmera</Text>
                <Text fontSize="sm">{video?.camera}</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Downloads</Text>
                <Text fontSize="sm">{video?.totalDownloads}</Text>
              </GridItem>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Formato</Text>
                <Text fontSize="sm">{video?.format}</Text>
              </GridItem>
            </Grid>
            {/* ...outras características técnicas */}
          </Box>

          <Box p={10} borderRadius="lg" borderWidth={1} borderColor={colors.blackBorder}>
            <Text fontSize="sm">Produção e Locação</Text>
            <Text fontSize="xs" color={colors.white50} mb={2}>Detalhes de Copyright e Filmagem</Text>

            <Grid templateColumns='repeat(2, 1fr)' mt={5} gap={5}>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Localização</Text>
                <Text fontSize="xs">{video?.local}</Text>
              </GridItem>
              <GridItem>
                <GhostButton
                  text="Ver no mapa"
                  icon={<Image src={require("../../assets/icons/map-pin.png")} w="15px" />}
                  onClick={() => {
                    if (mapContainerRef.current) {
                      mapContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                  }}
                />
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' mt={5} gap={5}>
              <GridItem>
                <Text fontSize="xs" color={colors.white50}>Endereço</Text>
                <Text fontSize="sm">{video?.city} - {video?.local} - {video?.state} - {video?.country}</Text>
              </GridItem>
            </Grid>

            <Grid templateColumns='repeat(2, 1fr)' mt={5} gap={5} borderTopWidth={1} pt={5}>
              <GridItem>
                <Flex justify="flex-start" align="center" alignSelf="center">
                  <Avatar name={video?.fullName} size="sm" backgroundColor={colors.primary} color={colors.black} />
                  <Text ml={3} fontSize="xs">Criado por <Text fontWeight="bold">{video?.fullName}</Text></Text>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
        </Grid>

        <Box mt={20}>
          <Text fontSize="xs">Similares</Text>
          <Text fontSize="3xl" fontWeight="500" mb={5}>Com base nas categorias de seu vídeo</Text>
          <Videos videos={videos} setVideos={setVideos} />
        </Box>
      </Container >

      <div style={{ position: 'relative', height: '700px', width: '100%', marginTop: 100 }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: '300px', zIndex: 401, backgroundImage: `linear-gradient(to top, transparent, black)` }} />
        <Container maxW="container.xl" position="relative" zIndex={404}>
          <Text fontSize="xs" position="absolute" top="100">Similares</Text>
          <Text fontSize="xl" fontWeight="500" mb={5} position="absolute" top="120">Descrição e cena do vídeo</Text>
        </Container>
        {video && <>
          <div ref={mapContainerRef}>
            <MapContainer center={[video?.gPSLat, video?.gPSLong]} zoom={10} style={{ position: 'relative', height: '700px', width: '100%', pointerEvents: 'none' }} scrollWheelZoom={false}>

              <TileLayer
                url={'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'}
                attribution=""
              />
              {[{ id: 1, position: [video?.gPSLat, video?.gPSLong], title: 'Pin 1' }].map((pin) => {
                const myCustomIcon = new L.DivIcon({
                  html: `<div style="background: transparent; border: none; font-size: 12px; color: #FFCC00;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24" height="24" fill="currentColor">
              <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 98.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/>
            </svg></div>`,
                  iconSize: [40, 40],
                  iconAnchor: [20, 20]
                });

                return (
                  <Marker key={pin.id} position={pin.position} icon={myCustomIcon} />
                );
              })}
            </MapContainer>
          </div>
        </>}
      </div>

      <Container maxW="container.xl" mt={"-150px"} position="relative" zIndex={401} mb={20}>
        <Box mt={20}>
          <Videos videos={videos2} setVideos={setVideos2} />
        </Box>
      </Container >

      <Footer />
    </>
  );
};

export default Product;
