import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import api, { exceptionNotificationAPI } from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const token = localStorage.getItem('@HaustriApp:token');
        const user = localStorage.getItem('@HaustriApp:user');

        if (token && user) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return { token, user: JSON.parse(user) };
        }
        return null;
    });

    const signIn = async ({ email, password }) => {
        try {
            const response = await api.post('/authenticate', { email, password });
            const r = response.data;
            api.defaults.headers.authorization = `Bearer ${r.token}`;
            localStorage.setItem('@HaustriApp:token', r.token);
            localStorage.setItem('@HaustriApp:user', JSON.stringify(r));
            localStorage.setItem('@HaustriApp:userName', email);
            setUser({ token: r.token, user: r });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Tratar o erro de autenticação aqui
                return { error: error.response.data.message };
            } else {
                // Tratar outros erros
                exceptionNotificationAPI(error);
            }
        }
    };


    const signOut = () => {
        api.defaults.headers.authorization = '';
        localStorage.removeItem('@HaustriApp:token');
        localStorage.removeItem('@HaustriApp:user');
        localStorage.removeItem('@HaustriApp:userName');
        setUser(null);
        window.location.href = "/";
    }

    const [headerExpanded, setHeaderExpanded] = useState(localStorage.getItem('@HaustriApp:isExpanded'));

    useEffect(() => {
        localStorage.setItem('@HaustriApp:isExpanded', null);
        setHeaderExpanded(null);
    }, [])

    const headerExpand = () => {
        localStorage.setItem('@HaustriApp:isExpanded', moment());
        setHeaderExpanded(localStorage.getItem('@HaustriApp:isExpanded'));
    }

    useEffect(() => {
        setHeaderExpanded(localStorage.getItem('@HaustriApp:isExpanded'));
    }, [localStorage.getItem('@HaustriApp:isExpanded')]);

    const [faturamentoOpen, setFaturamentoOpen] = useState(false);

    const faturamentoOpenModal = () => {
        setFaturamentoOpen(!faturamentoOpen);
    }

    return (
        <AuthContext.Provider value={{ user: user?.user, signIn, signOut, headerExpand, headerExpanded, faturamentoOpenModal, faturamentoOpen }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
}
