import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Container, Grid, GridItem, Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import Api from '../../services/api';
import { colors } from '../../styles/colors';

const UserRegistrationList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    async function loadUsers() {
        setLoading(true);
        try {
            const result = await Api.get('/users/registration/list');
            setData(result.data);
        } catch (error) {
            console.error('Erro ao carregar a lista de usuários:', error);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <Header />
            <div style={{ paddingBottom: 25, paddingTop: 25, position: 'relative', backgroundColor: 'transparent' }}>
                <Container maxW="container.xl">
                    <Box mt={30}>
                        <Text fontSize="xl" mb={10}>
                            Lista de Usuários com Data de Cadastro
                        </Text>
                        <Grid templateColumns="repeat(5, 1fr)" gap={4} my={5} alignItems="center">
                            <GridItem><Text fontWeight="bold" color={colors.white75}>Id</Text></GridItem>
                            <GridItem><Text fontWeight="bold" color={colors.white75}>Nome</Text></GridItem>
                            <GridItem><Text fontWeight="bold" color={colors.white75}>Telefone</Text></GridItem>
                            <GridItem><Text fontWeight="bold" color={colors.white75}>Email</Text></GridItem>
                            <GridItem><Text fontWeight="bold" color={colors.white75}>Data de Cadastro</Text></GridItem>
                        </Grid>

                        {data.map((user, index) => (
                            <Grid templateColumns="repeat(5, 1fr)" gap={4} py={2} alignItems="center" key={index}>
                                <GridItem><Text color={colors.white50}>{user.IdUser}</Text></GridItem>
                                <GridItem><Text color={colors.white}>{user.FullName}</Text></GridItem>
                                <GridItem><Text color={colors.white50}>{user.Phone}</Text></GridItem>
                                <GridItem><Text color={colors.white}>{user.Email}</Text></GridItem>
                                <GridItem><Text color={colors.white50}>{moment(user.registrationDate).utcOffset('-03:00').format('DD/MM/YYYY HH:mm')}</Text></GridItem>
                            </Grid>
                        ))}

                        {data.length === 0 && (
                            <Grid templateColumns="repeat(5, 1fr)" gap={4} py={2} alignItems="center">
                                <GridItem colSpan={5}>
                                    <Text color={colors.white50}>Nenhum usuário encontrado.</Text>
                                </GridItem>
                            </Grid>
                        )}
                    </Box>
                </Container>
            </div>
        </div>
    );
};

export default UserRegistrationList;
