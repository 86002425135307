import { Spinner, VStack } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../styles/colors';

export const Loading = ({ p = 5 }) => {
    return (
        <VStack p={p} justifyContent="center" alignContent="center" alignItems="center" w="full">
            <Spinner thickness='4px' speed='0.65s' emptyColor={colors.background} color={colors.primary} size='xl' />
        </VStack>
    );
};