import { Button, IconButton } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../styles/colors';

export const IconGhostButton = ({ text, icon = null, variant = "ghost", color = colors.white, borderRadius = 50, onClick, ...rest }) => {
    return (
        <IconButton
            variant={variant}
            color={color}
            icon={icon}
            onClick={onClick}
            borderRadius={borderRadius}
            fontSize="sm"
            {...rest}
        >
            {text}
        </IconButton>
    );
};

export const GhostButton = ({ text, icon = null, variant = "ghost", color = colors.white, borderRadius = 50, onClick, ...rest }) => {
    return (
        <Button
            variant={variant}
            color={color}
            leftIcon={icon}
            onClick={onClick}
            borderRadius={borderRadius}
            fontWeight="400"
            fontSize="sm"
            {...rest}
        >
            {text}
        </Button>
    );
};

export const YellowButton = ({ text, icon = null, borderRadius = 50, onClick, ...rest }) => {
    return (
        <Button
            variant="solid"
            leftIcon={icon}
            colorScheme="yellow"
            backgroundColor={colors.primary}
            color={colors.black}
            onClick={onClick}
            borderRadius={borderRadius}
            fontWeight="400"
            fontSize="sm"
            {...rest}
        >
            {text}
        </Button>
    );
};


export const BlackButton = ({ text, icon = null, borderRadius = 50, onClick, ...rest }) => {
    return (
        <Button
            variant="solid"
            leftIcon={icon}
            colorScheme="black.100"
            backgroundColor={colors.black}
            color={colors.primary}
            onClick={onClick}
            borderRadius={borderRadius}
            fontWeight="400"
            fontSize="sm"
            {...rest}
        >
            {text}
        </Button>
    );
};

export const WhiteButton = ({ text, icon = null, borderRadius = 50, onClick, ...rest }) => {
    return (
        <Button
            variant="solid"
            leftIcon={icon}
            colorScheme="white"
            backgroundColor={colors.white}
            color={colors.black}
            onClick={onClick}
            borderRadius={borderRadius}
            fontWeight="400"
            fontSize="sm"
            {...rest}
        >
            {text}
        </Button>
    );
};