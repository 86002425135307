import { Box, Container, Grid, GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GhostButton, YellowButton } from '../../components/button';
import Header from '../../components/header';
import { CustomSelect2 } from '../../components/input';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';
import { Loading } from '../../components/loading';

const WalletApproval = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function load() {
    setLoading(true);
    var result = await Api.get("/withdrawals/pending");
    setData(Object.values(result.data));
    setFilteredData(Object.values(result.data));
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [])

  async function approve(id) {
    setLoading(true);
    try {
      await Api.post(`/withdrawal/approve/${id}`);
      load();
    } catch (error) {
      console.error('Erro ao aprovar o vídeo:', error);
      setLoading(false);
    }
  }

  async function reject(id) {
    setLoading(true);
    try {
      await Api.post(`/withdrawal/reject/${id}`, { reason: rejectionReason });
      load();
    } catch (error) {
      console.error('Erro ao reprovar o vídeo:', error);
      setLoading(false);
    }
  }

  const [selectedValues, setSelectedValues] = useState({ value: "All", label: "Todos" });
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filteredData2 = selectedValues?.value != "All"
      ? data.filter(x => selectedValues.value == x.ApprovalStatus)
      : data;
    setFilteredData(filteredData2);
  }, [selectedValues])

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header />
        <div style={{ paddingBottom: 25, paddingTop: 25, position: 'relative', backgroundColor: "transparent" }}>
          <Container maxW="container.xl" justifyContent="space-between" alignItems="center">
            <Box mt={30}>
              <Text fontSize="xl" mb={5}>
                Saques para aprovar/reprovar
              </Text>
              <CustomSelect2
                placeholder="Filtrar por status"
                options={[
                  { value: "All", label: "Todos" },
                  { value: "Pending", label: "Pendente" },
                  { value: "Approved", label: "Aprovado" },
                  { value: "Rejected", label: "Reprovado" },
                ]}
                value={selectedValues}
                onChange={value => setSelectedValues(value)}
              />


              {loading ? <Loading /> : <>
                <Grid templateColumns="repeat(10, 1fr)" gap={2} mt={10} mb={5} alignItems="center">
                  <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Id</Text></GridItem>
                  <GridItem colSpan={2}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Criador</Text></GridItem>
                  <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Valor Solicitado</Text></GridItem>
                  <GridItem colSpan={2}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Saldo Carteira</Text></GridItem>
                  <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>PIX</Text></GridItem>
                  <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Data envio</Text></GridItem>
                  <GridItem colSpan={2} display="flex" gap={2} justifyContent="flex-end"><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Ações/Status</Text></GridItem>
                </Grid>

                {filteredData?.map((x, index) => (
                  <Grid templateColumns="repeat(10, 1fr)" gap={2} py={4} alignItems="center" key={index} backgroundColor={x.ApprovalStatus == "Pending" ? colors.whiteTransparent : "transparent"}>
                    <GridItem colSpan={1}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>{x.IdWithdrawal}</Text></GridItem>
                    <GridItem colSpan={2}><Text color={colors.white} style={{ wordBreak: 'break-all' }}>{x.FullName}</Text></GridItem>
                    <GridItem colSpan={1}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>R$ {x.WithdrawalValue ?? "0.00"}</Text></GridItem>
                    <GridItem colSpan={2}>
                      {x.ApprovalStatus == "Pending" && <>
                        <Text color={colors.white50} style={{ wordBreak: 'break-all' }}>R$ {x.AvailableBalance ?? "0.00"} (Pendente: R$ {x.PendingBalance ?? "0.00"})</Text>
                      </>}
                    </GridItem>
                    <GridItem colSpan={1}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>({x.TypeOfPixKey}) {x.KeyPix}</Text></GridItem>
                    <GridItem colSpan={1}><Text color={colors.white50}>{moment(x.RequestDate).format("DD/MM/YYYY")}</Text></GridItem>
                    <GridItem colSpan={2} display="flex" gap={2} justifyContent="flex-end">
                      {x.ApprovalStatus == "Pending" && <>
                        <GhostButton backgroundColor={colors.green} text="Aprovar" onClick={() => approve(x.IdWithdrawal)} />
                        <GhostButton backgroundColor={colors.red} text="Reprovar" onClick={() => { setId(x.IdWithdrawal); setIsRejectModalOpen(true) }} />
                      </>}
                      {x.ApprovalStatus == "Approved" && <>
                        <Text color={colors.green} style={{ wordBreak: 'break-all' }}>Aprovado</Text>
                      </>}
                      {x.ApprovalStatus == "Rejected" && <>
                        <Text color={colors.red} style={{ wordBreak: 'break-all' }}>Reprovado</Text>
                      </>}
                    </GridItem>
                  </Grid>
                ))}

                {filteredData?.length == 0 && <Grid templateColumns="repeat(10, 1fr)" gap={4} py={2} alignItems="center">
                  <GridItem colSpan={10}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>Nenhum registro a ser exibido.</Text></GridItem>
                </Grid>}
              </>}
            </Box>
          </Container>
        </div>
      </div>

      <Modal isCentered isOpen={isRejectModalOpen} onClose={() => setIsRejectModalOpen(false)}>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
        <ModalContent backgroundColor={colors.background} color={colors.white}>
          <ModalHeader>Reprovar vídeo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>Por favor, informe o motivo da rejeição:</Text>
            <Textarea colorScheme='yellow' value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} />
          </ModalBody>
          <ModalFooter>
            <YellowButton
              text="Enviar motivo"
              mr={3}
              onClick={() => {
                reject(id);
                setIsRejectModalOpen(false);
                setRejectionReason("");
              }}
            />
            <GhostButton text="Cancelar" onClick={() => { setIsRejectModalOpen(false); setRejectionReason(""); }} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WalletApproval;
