import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import AppProvider from './hooks/index';
import RoutesList from './routes';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
import useSecurityMeasures from './hooks/useSecurityMeasures';
import { RightClickModal } from './components/rightClickModal';

const ENV = process.env.REACT_APP_ENV || 'PRD'; // Adicione a variável de ambiente

function App() {
    const { isOpen, onClose } = useSecurityMeasures(ENV);

    return (
        <>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <ChakraProvider theme={theme}>
                <AppProvider>
                    <RoutesList forceRefresh={true} />
                </AppProvider>
                <GlobalStyle />
                <RightClickModal isOpen={isOpen} onClose={onClose} />
            </ChakraProvider>
        </>
    );
}

export default App;
