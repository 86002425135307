import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';

const useSecurityMeasures = (env) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (env === 'PRD') {
            const handleContextMenu = (event) => {
                event.preventDefault();
                onOpen();
            };

            const handleKeyDown = (event) => {
                if (event.key === 'F12') {
                    event.preventDefault();
                }
                if (event.ctrlKey && event.shiftKey && event.key === 'I') {
                    event.preventDefault();
                }
                if (event.ctrlKey && event.shiftKey && event.key === 'J') {
                    event.preventDefault();
                }
                if (event.ctrlKey && event.key === 'U') {
                    event.preventDefault();
                }
            };

            document.addEventListener('contextmenu', handleContextMenu);
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('contextmenu', handleContextMenu);
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [env, onOpen]);

    return { isOpen, onClose };
};

export default useSecurityMeasures;
