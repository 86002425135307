import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalCloseButton, ModalContent, ModalOverlay, Text, HStack } from '@chakra-ui/react';
import { YellowButton, GhostButton } from './button';
import { colors } from '../styles/colors';
import { Loading } from './loading';

export const ConfirmationModal = ({ isOpen, onClose, onConfirm, video }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);
    const [attempts, setAttempts] = useState(0);

    useEffect(() => {
        if (isSuccess === true) {
            const timer = setTimeout(() => {
                handleClose();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isSuccess]);

    const handleClose = () => {
        onClose();
        resetState();
    };

    const resetState = () => {
        setLoading(false);
        setMessage('');
        setIsSuccess(null);
        setAttempts(0);
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
            setIsSuccess(true);
            setMessage(video?.isOnline ? 'Seu vídeo foi retirado do ar!' : 'Parabéns! Seu vídeo está de volta no ar e disponível para ser compra.');
        } catch (error) {
            setIsSuccess(false);
            setMessage('Ocorreu um erro ao atualizar o status do vídeo.');
            setAttempts(attempts + 1);
        } finally {
            setLoading(false);
        }
    };

    const handleRetry = () => {
        if (attempts < 3) {
            handleConfirm();
        } else {
            setMessage('Muitas tentativas falharam. Por favor, contate o time de suporte.');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="md" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    {video?.isOnline ? 'Remover Vídeo' : 'Restaurar Vídeo'}
                </Text>
                <ModalBody>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Text fontSize="md" color={colors.white75} mb={4}>
                                {message || (video?.isOnline ? 'Você tem certeza que deseja remover este vídeo?' : 'Você tem certeza que deseja restaurar este vídeo?')}
                            </Text>
                        </>
                    )}
                </ModalBody>
                {!loading && isSuccess === null && (
                    <ModalFooter justifyContent="center">
                        <HStack spacing={4} w="full" justify="center">
                            <GhostButton text="Cancelar" onClick={handleClose} borderColor={colors.gray} borderWidth={1} color={colors.gray} />
                            <YellowButton text="Confirmar" onClick={handleConfirm} />
                        </HStack>
                    </ModalFooter>
                )}
                {!loading && isSuccess === true && (
                    <ModalFooter justifyContent="center">
                        <GhostButton text="Fechar" onClick={handleClose} borderColor={colors.gray} borderWidth={1} color={colors.gray} />
                    </ModalFooter>
                )}
                {!loading && isSuccess === false && (
                    <ModalFooter justifyContent="center">
                        <HStack spacing={4} w="full" justify="center">
                            <GhostButton text="Fechar" onClick={handleClose} borderColor={colors.gray} borderWidth={1} color={colors.gray} />
                            <YellowButton text="Tentar novamente" onClick={handleRetry} />
                        </HStack>
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    );
};
