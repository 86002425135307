import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text, Box, VStack } from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const PrivacyPolicy = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Política de Privacidade
                </Text>
                <ModalBody>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        A HausTri respeita a privacidade de seus usuários e está comprometida em proteger as informações pessoais coletadas através de sua plataforma. Esta política descreve como coletamos, usamos, divulgamos e protegemos essas informações.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Coleta de Informações:</strong> As informações são coletadas quando você se cadastra na plataforma, utiliza nossos serviços ou participa de atividades específicas. Incluem dados pessoais como nome, e-mail e informações de pagamento.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Uso de Informações:</strong> Utilizamos suas informações para processar transações, fornecer suporte, aumentar a segurança de nosso serviço e comunicar ofertas e atualizações.
                    </Text>
                    <Text fontSize="md" color={colors.white75}>
                        <strong>Compromisso com a LGPD:</strong> Estamos comprometidos com a conformidade com a Lei Geral de Proteção de Dados (LGPD) e outras leis de proteção de dados aplicáveis. Empregamos medidas de segurança robustas para proteger seus dados contra acesso não autorizado e violação.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
