import { Button, ButtonGroup } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../styles/colors';

export const ButtonGroupCustom = ({ active, buttons, ...rest }) => {
    return (
        <ButtonGroup spacing={0} {...rest}>
            {buttons.map((button, index) => {
                return <Button
                    key={index}
                    backgroundColor={colors.blackGroup}
                    color={button.id === active ? colors.white : colors.white50}
                    className={index === 0 ? "rounded-r-none" : index === buttons.length - 1 ? "rounded-l-none" : "rounded-none"}
                    colorScheme="gray"
                    fontWeight="400"
                    padding={7}
                    onClick={button.onClick}
                >
                    {button.text}
                </Button>
            })}
        </ButtonGroup>
    );
};