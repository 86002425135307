import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Input, Select as Select2, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { colors } from '../styles/colors';
import { GhostButton } from './button';

export const CustomInput = ({ placeholder, backgroundColor = colors.grayInput75, onChange, value, type = 'text', size = "lg", error, ...rest }) => {
    return (
        <Input
            autoComplete='off'
            {...rest}
            backgroundColor={backgroundColor}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            size={size}
            fontSize="md"
            _focus={{
                borderWidth: 1,
                borderColor: colors.primary,
                boxShadow: '0 0 0 1px ' + colors.primary,
            }}
            borderColor={error ? 'red.500' : colors.white50}
        />
    );
};

export const CustomFormInput = ({ id, label, errors, values, setValues, timeout = 300, ...rest }) => {
    const errorObj = errors?.find(x => x.path === id);
    const [inputValue, setInputValue] = useState(values?.[id] || '');

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setValues((prevValues) => ({ ...prevValues, [id]: inputValue }));
        }, timeout);

        return () => clearTimeout(timeoutId);
    }, [inputValue, id, setValues, timeout]);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <FormControl id={id} mb={3} isInvalid={!!errorObj}>
            <FormLabel fontSize="sm" fontWeight="300" opacity={0.35}>
                {label}
            </FormLabel>
            <CustomInput
                {...rest}
                value={inputValue}
                onChange={handleChange}
                onBlur={() => setValues((prevValues) => ({ ...prevValues, [id]: inputValue }))}
            />
            {errorObj && (
                <FormErrorMessage fontSize="sm">
                    {errorObj.msg}
                </FormErrorMessage>
            )}
        </FormControl>
    );
};


export const CustomInputTag = ({ placeholder, onChange, value, type = 'text', size = "lg", error, onEnter, ...rest }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onEnter && onEnter(e.target.value);
        }
    };

    return (
        <Input
            autoComplete='off'
            {...rest}
            backgroundColor={colors.grayInput75}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            size={size}
            fontSize="md"
            _focus={{
                borderWidth: 1,
                borderColor: colors.primary,
                boxShadow: '0 0 0 1px ' + colors.primary,
            }}
            borderColor={error ? 'red.500' : 'inherit'}
            onKeyDown={handleKeyDown}
        />
    );
};

export const CustomFormInputTag = ({ id, label, errors, values, setValues, ...rest }) => {
    const [tags, setTags] = useState(values[id] || []);
    const [tagInput, setTagInput] = useState('');

    const errorObj = errors?.find(x => x.path === id);

    const handleAddTag = () => {
        if (tagInput.trim() === '') return;

        const newTags = [...tags, { description: tagInput }];
        setTags(newTags);
        setValues((prevValues) => ({ ...prevValues, [id]: newTags }));
        setTagInput('');
    };

    const handleRemoveTag = (tag) => {
        const newTags = tags.filter(t => t.description !== tag.description);
        setTags(newTags);
        setValues((prevValues) => ({ ...prevValues, [id]: newTags }));
    };

    return (
        <FormControl id={id} mb={3} isInvalid={!!errorObj}>
            <FormLabel fontSize="sm" fontWeight="300" opacity={0.35}>
                {label}
            </FormLabel>
            <HStack>
                <CustomInput
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddTag();
                        }
                    }}
                    {...rest}
                />
                <GhostButton text="+" onClick={handleAddTag} />
            </HStack>
            <Box mt={2}>
                {tags.map((tag, index) => (
                    <Tag
                        key={index}
                        m={1}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="yellow"
                        backgroundColor={colors.primary}
                        color={colors.black}
                    >
                        <TagLabel>{tag.description}</TagLabel>
                        <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                    </Tag>
                ))}
            </Box>
            {errorObj && (
                <FormErrorMessage fontSize="sm">
                    {errorObj.msg}
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

export const CustomFormSelect = ({ id, label, errors, values, setValues, options, ...rest }) => {
    const errorObj = errors?.find(x => x.path === id);
    const handleChange = (e) => {
        setValues((prevValues) => ({ ...prevValues, [id]: e.target.value }));
    };

    return (
        <FormControl id={id} mb={3} isInvalid={!!errorObj}>
            <FormLabel fontSize="sm" fontWeight="300" opacity={0.35}>
                {label}
            </FormLabel>
            <CustomSelect
                {...rest}
                error={!!errorObj}
                value={values[id] || ''}
                onChange={handleChange}
                options={options}
            />
            {errorObj && (
                <FormErrorMessage fontSize="sm">
                    {errorObj.msg}
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

const customStyles = {
    container: (provided) => ({
        ...provided,
        minWidth: "95%",
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: colors.black,
        border: '1px solid ' + colors.white50, // Adiciona uma borda amarela ao passar o mouse sobre o seletor
        minHeight: "3rem",
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid ' + colors.primary, // Adiciona uma borda amarela ao passar o mouse sobre o seletor
        },
    }),
    input: (provided) => ({
        ...provided,
        fontSize: 'md',
        padding: 2,
        border: 'none',
        '&:hover': {
            border: 'none',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: colors.primary,
        colors: colors.black,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'black', // Cor do "X" de remoção
        ':hover': {
            backgroundColor: 'transparent',
            color: 'black',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: colors.black,
        color: state.isSelected ? 'white' : 'inherit',
        '&:hover': {
            backgroundColor: colors.primary,
            color: colors.black,
        },
        zIndex: 100,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'inherit',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: colors.white75,
    }),
};

export const CustomSelect = ({ placeholder, onChange, value, size = 'lg', error, options = [], ...rest }) => {
    return (
        <Select2
            autoComplete="off"
            {...rest}
            backgroundColor={colors.grayInput75}
            placeholder={placeholder}
            onChange={onChange}
            value={value || ""}
            size={size}
            fontSize="md"
            _focus={{
                borderWidth: 1,
                borderColor: colors.primary,
                boxShadow: '0 0 0 1px ' + colors.primary,
            }}
            borderColor={error ? 'red.500' : 'inherit'}
        >
            <option value="" disabled>Selecione uma opção</option>
            {options?.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </Select2>
    );
};
export const CustomSelect2 = ({ placeholder, onChange, value, options = [], isMulti = false, ...rest }) => {
    return (
        <FormControl mb={3}>
            <FormLabel fontSize="sm" fontWeight="300" opacity={0.35}>{placeholder}</FormLabel>
            <Select
                {...rest}
                isMulti={isMulti}
                styles={{
                    ...customStyles,
                    menu: base => ({ ...base, backgroundColor: colors.black, borderWidth: 1, zIndex: 4 }),
                }}
                placeholder={"Selecione"}
                onChange={onChange}
                value={options.find(option => option.value === value)}
                options={options}
            />
        </FormControl>
    );
};