import { useAuth } from "../hooks/auth";
import Home from '../pages/Home';
import Authorized from "../pages/Layouts/Authorized";

const RouteWrapper = ({ element: Element, admin = false, ...props }) => {
    const { user } = useAuth();

    if (admin) {
        if (!user) {
            Element = Home;
        }

        const hasAdministratorRole = user && Object.values(user.roles).some(role => role.description === "administrator");
        if (user && !hasAdministratorRole) {
            Element = Home;
        }
    }

    const Layout = Authorized;

    return (
        <Layout>
            <Element />
        </Layout>
    );
};

export default RouteWrapper;