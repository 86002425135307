import { Box, Container, Grid, GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GhostButton, YellowButton } from '../../components/button';
import Header from '../../components/header';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';

const VideoApproval = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openId, setOpenId] = useState(false);
  const [id, setId] = useState(false);
  const [isValid, setIsValid] = useState(false);

  async function load() {
    setLoading(true);
    var result = await Api.get("/video/approve/list");
    setData(Object.values(result.data));
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [])

  async function approveVideo(videoId) {
    setLoading(true);
    try {
      await Api.put(`/video/approve`, { idVideo: videoId, rejectReason: "" });
      load();
    } catch (error) {
      console.error('Erro ao aprovar o vídeo:', error);
      setLoading(false);
    }
  }

  async function rejectVideo(reason) {
    setLoading(true);
    try {
      await Api.put(`/video/approve`, { idVideo: id, rejectReason: reason });
      load();
    } catch (error) {
      console.error('Erro ao reprovar o vídeo:', error);
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    setRejectionReason(value);
    setIsValid(value.length >= 10 && value.length <= 256);
  };

  const handleSubmit = () => {
    if (isValid) {
      rejectVideo(rejectionReason);
      setIsRejectModalOpen(false);
      setRejectionReason("");
    }
  };

  const handleClose = () => {
    setIsRejectModalOpen(false);
    setRejectionReason("");
    setIsValid(false);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header />
        <div style={{ paddingBottom: 25, paddingTop: 25, position: 'relative', backgroundColor: "transparent" }}>
          <Container maxW="container.xl" justifyContent="space-between" alignItems="center">
            <Box mt={30}>
              <Text fontSize="xl" mb={10}>
                Vídeos para aprovar/reprovar
              </Text>
              <Grid templateColumns="repeat(8, 1fr)" gap={4} my={5} alignItems="center">
                <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Id</Text></GridItem>
                <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Titulo</Text></GridItem>
                <GridItem colSpan={2}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Descrição</Text></GridItem>
                <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Link</Text></GridItem>
                <GridItem colSpan={1}><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Data envio</Text></GridItem>
                <GridItem colSpan={2} display="flex" gap={4} justifyContent="flex-end"><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Ações</Text></GridItem>
              </Grid>

              {data?.map((x, index) => (
                <Grid templateColumns="repeat(8, 1fr)" gap={4} py={2} alignItems="center" key={index} backgroundColor={selectedVideo == x.videoLinkCompressedAndWatermark ? colors.whiteTransparent : "transparent"}>
                  <GridItem colSpan={1}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>{x.idVideo}</Text></GridItem>
                  <GridItem colSpan={1}><Text color={colors.white} style={{ wordBreak: 'break-all' }}>{x.titleScene}</Text></GridItem>
                  <GridItem colSpan={2}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>{x.descriptionScene}</Text></GridItem>
                  <GridItem colSpan={1}><Text color={colors.white}><a href="#" onClick={() => { setOpenId(true); setSelectedVideo(x.videoLinkCompressedAndWatermark); }}>Ver vídeo</a></Text></GridItem>
                  <GridItem colSpan={1}><Text color={colors.white50}>{moment(x.creationDate).format("DD/MM/YYYY")}</Text></GridItem>
                  <GridItem colSpan={2} display="flex" gap={4} justifyContent="flex-end">
                    <GhostButton backgroundColor={colors.green} text="Aprovar" onClick={() => approveVideo(x.idVideo)} />
                    <GhostButton backgroundColor={colors.red} text="Reprovar" onClick={() => { setId(x.idVideo); setIsRejectModalOpen(true); }} />
                  </GridItem>
                </Grid>
              ))}

              {data?.length == 0 && <Grid templateColumns="repeat(8, 1fr)" gap={4} py={2} alignItems="center">
                <GridItem colSpan={8}><Text color={colors.white50} style={{ wordBreak: 'break-all' }}>Nenhum registro a ser exibido.</Text></GridItem>
              </Grid>}
            </Box>
          </Container>
        </div>

        <Modal isCentered isOpen={isRejectModalOpen} onClose={handleClose}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
          <ModalContent backgroundColor={colors.background} color={colors.white}>
            <ModalHeader>Reprovar vídeo</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={2}>Por favor, informe o motivo da rejeição:</Text>
              <Textarea
                colorScheme='yellow'
                value={rejectionReason}
                onChange={handleChange}
                isInvalid={!isValid}
              />
              {!isValid && (
                <Text color="red.500" fontSize="sm" mt={2}>
                  O motivo deve ter entre 10 e 256 caracteres.
                </Text>
              )}
            </ModalBody>
            <ModalFooter>
              <YellowButton
                text="Enviar motivo"
                mr={3}
                onClick={handleSubmit}
                isDisabled={!isValid}
              />
              <GhostButton text="Cancelar" onClick={handleClose} />
            </ModalFooter>
          </ModalContent>
        </Modal>

        {openId && (
          <Modal isCentered isOpen={openId} onClose={() => setOpenId(false)}>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} color={colors.white}>
              <ModalHeader>Visualizar vídeo</ModalHeader>
              <ModalCloseButton />
              <ModalBody my={5}>
                <video controls>
                  <source src={selectedVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </div >
    </>
  );
};

export default VideoApproval;
