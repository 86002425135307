import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Flex, InputGroup, InputRightElement, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GhostButton } from '../../components/button';
import { ButtonGroupCustom } from '../../components/buttonGroup';
import { Footer } from '../../components/footer';
import Header from '../../components/header';
import HorizontalScrollingText from '../../components/horizontalScrollingText';
import { CustomInput } from '../../components/input';
import { Loading } from '../../components/loading';
import { VideoSliderHome } from '../../components/slider';
import { VideoPlayerHeader } from '../../components/video';
import { Videos } from '../../components/videos';
import { useAuth } from '../../hooks/auth';
import Api from '../../services/api';
import { colors } from '../../styles/colors';

const Home = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const inputRef = useRef(null);

  const placeholderText = useBreakpointValue({
    base: 'ex. praia no RJ', // Placeholder para dispositivos móveis
    md: 'ex. palmeiras na praia no rio de janeiro', // Placeholder para tablets e desktops
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, []);

  const [texto, setTexto] = useState("");

  const [videosHighlight, setVideosHighlight] = useState([]);

  const [loadingVideos, setLoadingVideos] = useState(true);
  const [videos, setVideos] = useState([]);

  const [allCategories, setAllCategories] = useState(null);
  const [categories, setCategories] = useState(null);
  const [activeCategorie, setActiveCategorie] = useState(-1);

  const dataLoadedRef = useRef(false);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Número aleatório entre 0 e i
      [array[i], array[j]] = [array[j], array[i]]; // Troca os elementos de lugar
    }
    return array;
  }

  async function load() {
    try {
      const [videohighlightsRes, categoriesRes] = await Promise.all([
        Api.get('/video/highlights'),
        Api.get('/categories')
      ]);

      const videohighlights = Object.values(videohighlightsRes.data).map(x => ({
        id: x.IdVideo,
        videoUrl: x.VideoLinkCompressedAndWatermark,
        thumbnailUrl: x.ThumbMomentImgLink,
        text: x.HighlightTitle,
        description: x.HighlightSubTitle,
        onClick: () => navigate("/produto/" + x.IdVideo)
      }));
      setVideosHighlight(videohighlights);

      const categoriesArray = Object.values(categoriesRes.data);
      setAllCategories(categoriesArray);

      const shuffledCategories = shuffleArray(categoriesArray);
      const selectedCategories = shuffledCategories.slice(0, 9);
      setCategories(selectedCategories);

      getVideos();
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  async function getVideos(categoryId = activeCategorie) {
    setLoadingVideos(true);
    try {
      const response = await Api.post('/video/filters', {
        idUser: user?.id ? user.id : 0,
        page: 1,
        limitPerPage: 24,
        categoriesId: categoryId === -1 ? [] : [categoryId],
        randomOrder: true
      });
      const videos = response.data.videos.map(x => ({
        id: x.idVideo,
        title: x.titleScene,
        description: x.descriptionScene,
        price: x.price,
        isNews: x.isNews,
        commissionType: x.idCommissionValueType,
        thumbnailUrl: x.thumbMomentImgLink,
        videoUrl: x.videoLinkCompressedAndWatermark,
        city: x.city,
        local: x.local,
        state: x.state,
        displayName: x.displayName,
        fullName: x.fullName,
        resolution: x.resolution,
        duration: x.duration,
        isFavorite: x.isFavorite,
        totalDownloads: x.totalDownloads,
        categories: x.categories.map(cat => ({ id: cat.idCategories, description: cat.description })),
        tags: x.tags.map(tag => ({ id: tag.IdTags, description: tag.Description })),
        onClick: () => navigate("/produto/" + x.idVideo)
      }));
      setVideos(videos);
    } catch (error) {
      console.error('Error loading videos:', error);
    } finally {
      setLoadingVideos(false);
    }
  }

  useEffect(() => {
    if (!dataLoadedRef.current) {
      load();
      dataLoadedRef.current = true;
    }
  }, []);

  useEffect(() => {
    getVideos();
  }, [activeCategorie]);

  // Função para lidar com o evento de pressionar Enter
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate("/produtos", { state: { text: texto } });
    }
  };

  const fixedVideo = {
    videoUrl: process.env.REACT_APP_FIXED_VIDEO_URL || "https://haustristorage.blob.core.windows.net/haustricontainer/bannerVT01.mp4?sp=r&st=2024-07-27T14:22:53Z&se=2200-07-27T22:22:53Z&sv=2022-11-02&sr=b&sig=LTQOKwf98JK5Z%2Fj6%2B6QsJGb2cSZcWl%2BXbTLDLXknDFA%3D",
    text: [
      "HAUSTRI é daqui!",
      "Banco de Vídeos",
      "Genuinamente Brasileiro"
    ],
    description: "Vídeo Promocional",
    creator: "Home Produtora",
    createdIn: "Santa Cruz do Sul"
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header token={token} />
        <div style={{ height: '80vh', paddingBottom: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Container maxW="container.xl" display="flex" justifyContent="center" alignItems="center" height='full'>
            <VStack align="stretch" maxW={"2xl"} spacing={10}>
              <Box textAlign="center" color={colors.white} lineHeight="50px">
                {fixedVideo.text.map((line, index) => (
                  <Text key={index} fontSize={{ base: '4xl', md: '5xl' }} fontWeight="semibold">
                    {line}
                  </Text>
                ))}
              </Box>
              <InputGroup size='lg' backgroundColor={"transparent"} mt={18}>
                <CustomInput
                  backgroundColor={"transparent"}
                  onChange={(e) => setTexto(e.target.value)}
                  onKeyPress={handleKeyPress}
                  borderRadius={10}
                  placeholder={placeholderText}
                  ref={inputRef}
                />
                <InputRightElement>
                  <InputRightElement width='160px'>
                    <Button backgroundColor={colors.whiteTransparent} leftIcon={<FaSearch />} fontSize="md" size='lg' onClick={() => navigate("/produtos", { state: { text: texto } })}>
                      Buscar Vídeos
                    </Button>
                  </InputRightElement>
                </InputRightElement>
              </InputGroup>
            </VStack>
          </Container>

          <VideoPlayerHeader key={fixedVideo.id} videoUrl={fixedVideo.videoUrl} fadeState={"fade-in"} />
        </div>
      </div >

      <div style={{ marginTop: -300, height: 300, backgroundImage: `linear-gradient(to bottom, transparent, ${colors.black})` }}>
      </div>

      <Container maxW="container.xl" mt={-20} mb={20}>
        <Text fontSize="lg" fontWeight="600">DESTAQUES HAUS</Text>
        <Text fontSize="sm" fontWeight="200">Conteúdo produzido pelos melhores criadores</Text>

        <Box mt={10} pb={50} borderBottomWidth={1} borderBottomColor={colors.whiteTransparent}>
          <VideoSliderHome videos={videosHighlight} />
        </Box>

        <Box mt={50}>
          <Flex
            justifyContent={{ base: 'flex-start', lg: 'center' }} // Alinhamento responsivo
            overflowX="auto"
            width="100%"
            px={4} // Padding horizontal
          >
            <ButtonGroupCustom
              active={activeCategorie}
              buttons={
                categories && categories.length > 0
                  ? [
                    { id: -1, text: "Todos", onClick: () => setActiveCategorie(-1) },
                    ...categories
                      .filter((x) => x.isActive)
                      .map((x) => ({
                        id: x.idCategories,
                        text: x.description,
                        onClick: () => setActiveCategorie(x.idCategories),
                      })),
                  ]
                  : []
              }
            />
          </Flex>
        </Box>


        <Box mt={30}>
          {loadingVideos ? <Loading /> : <Videos videos={videos} setVideos={setVideos} />}
        </Box>

        <Box mt={70} justify="center" align="center">
          <GhostButton text={"Carregar mais conteúdo"} onClick={() => navigate("/produtos")} p={7} borderRadius={50} borderWidth={1} borderColor={colors.white75} />
        </Box>
      </Container>

      <Box justify="center" align="center" onClick={() => navigate("/produtos")} style={{ cursor: 'pointer' }}>
        {allCategories && <>
          <HorizontalScrollingText texts={[...allCategories, ...allCategories]} scrollDirection={"rightToLeft"} />
          <HorizontalScrollingText texts={[...allCategories, ...allCategories]} scrollDirection={"leftToRight"} />
        </>}
      </Box>

      <Footer />
    </>
  );
};

export default Home;
