import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const LicensePlatformModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Licença HausTri
                </Text>
                <ModalBody maxHeight="70vh" overflowY="auto">
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Acreditamos que o licenciamento de filmagens de alta qualidade não precisa ser complicado. É por isso que criamos esta licença simples, transparente e direta para todos os usuários.
                        Esta é uma comunidade em que boa parte dos usuários, também são produtores e o objetivo principal é buscar o benefício mútuo, portanto contamos com o senso de consciência de cada usuário cadastrado.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Mas o que a “licença” dá direito?</strong><br />
                        A partir do momento que você adquire o ativo, a licença dele permite o uso em qualquer tipo de projeto e em qualquer meio de comunicação.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Exemplos de projetos:</strong><br />
                        - Vídeos Comerciais<br />
                        - Vídeo clipes musicais<br />
                        - Vídeos empresariais/institucionais<br />
                        - Vídeos de casamentos<br />
                        - Transmissões online<br />
                        - Vídeos jornalísticos<br />
                        - Vídeos para sites<br />
                        - Filmes
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Exemplos de meios de comunicação:</strong><br />
                        - YouTube<br />
                        - Facebook<br />
                        - Instagram<br />
                        - TikTok<br />
                        - Twitter<br />
                        - Vimeo<br />
                        - TVs abertas e TVs por assinatura<br />
                        - Salas de cinemas
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Se você for contratado para criar um projeto, seu cliente estará coberto pela sua licença e poderá usar o projeto em qualquer lugar. Você e seus clientes podem apresentar e/ou reproduzir o Projeto publicamente, distribuí-lo em todos os meios de comunicação existentes, sites, plataformas, redes sociais, sites de compartilhamento de vídeos, televisão ou outros dispositivos de armazenamento de dados, você escolhe!
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Ativos com marca d’água</strong><br />
                        Se você for um usuário não pagante, só poderá baixar versões com marca d’água dos Ativos para visualização pessoal. Você está proibido de remover tais marcas d'água ou de usar versões com marca d'água em quaisquer Projetos ou de qualquer outra forma.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Seus ativos são seus para sempre</strong><br />
                        Após adquirir o ativo, você tem o direito de usá-lo para sempre (isso mesmo, eternamente), sem riscos de expirar. Os ativos que você adquirir estarão sempre disponíveis para download na aba biblioteca na sua conta de usuário dentro da plataforma.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Você é o proprietário da licença</strong><br />
                        Se você criar um projeto incorporando um ativo adquirido, poderá transferir esse projeto para seus clientes e para qualquer outra pessoa para que possam usar o projeto, porém a Licença é apenas sua.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>O que você NÃO pode fazer com os ativos:</strong><br />
                        Esta licença não permite usar os ativos para fornecer quaisquer serviços que concorram com a plataforma Haustri ou qualquer um de nossos produtores. Você também não pode revendê-los de forma alguma, como em coleções de vídeos, videotecas, playlists de vídeos, bancos de dados ou licenciamento, etc. Você não pode revender e/ou fazer upload dos Ativos ou quaisquer projetos para plataformas de banco de vídeos.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Você não pode usar os Ativos de qualquer maneira que seja ilegal, contrária a ordens judiciais e/ou que seja proibida pelas disposições de qualquer lei. Além disso, você não pode usar os Ativos de qualquer maneira que possa prejudicar a plataforma Haustri, seus artistas, quaisquer terceiros, ou seus direitos ou reputação.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Os Ativos não podem ser utilizados:</strong><br />
                        - no contexto de pornografia;<br />
                        - encorajar a violência, a abominação, o racismo, o ódio, as ameaças e a discriminação contra qualquer pessoa com base na raça, religião, sexo, orientação sexual, comunidade ou nacionalidade ou ferir os indefesos;<br />
                        - violação de privacidade;<br />
                        - calúnias e quaisquer outros conteúdos injuriosos ou ilegais.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Se o assunto do seu Projeto puder ser razoavelmente percebido como pouco lisonjeiro ou controverso (como propaganda que trata de infecções sexualmente transmissíveis), embora tenhamos as autorizações exigidas, você não poderá retratar intencionalmente o assunto de forma negativa e deverá indicar que o modelo não tem ligação com o conteúdo do Projeto (por exemplo: informando o seguinte: “Arquivo de imagens, posado por modelo”).
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Como entendemos que pode haver áreas confusas a esse respeito, entre em contato conosco para explicar o seu projeto e nossa equipe informará se isso está coberto. Reservamo-nos o direito de decidir em cada caso se um determinado uso de um Ativo é considerado uma violação do acima exposto, e você se compromete a aceitar a nossa decisão nesta matéria. Não se preocupe, temos grande qualificação profissional e bom senso.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Fazemos nossos melhores esforços para verificar cada ativo e garantir que sejam de ótima qualidade e estejam legalmente disponíveis. No entanto, você sabe, coisas acontecem e podemos descobrir que um determinado Ativo pode estar violando os direitos de terceiros. Embora tenhamos o direito de solicitar que você pare de usar tais ativos, faremos isso apenas em algumas situações raras. Se o fizermos, você deverá parar de usá-los imediatamente e fazer o possível para que alguém em seu nome cesse o uso de tais Ativos.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Lembra que dissemos que você não é o proprietário dos Ativos? Bem, isso também significa que você não pode registrar ou reivindicar a propriedade dos Ativos ou dos Projetos (ou disponibilizá-los de outra forma) por meio de qualquer sistema de detecção e/ou registro de conteúdo, como o Content ID (CID) do YouTube, o Gerenciador de direitos do Facebook, etc.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        <strong>Adquirir uma licença não é a mesma coisa que ter os direitos autorais</strong><br />
                        Você adquire o direito de uso, não o direito autoral.<br />
                        O direito de propriedade intelectual e todos os direitos autorais são única e exclusivamente dos produtores dos ativos comercializados. Você não tem e não terá quaisquer direitos sobre os Ativos ou no Site, excluindo o direito de uso conforme especificado nesta Licença.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};