import React from 'react';

export const VideoPlayer = ({ videoUrl, ...rest }) => {
    return (
        <video controls width="100%" {...rest} >
            <source src={videoUrl} type="video/mp4" />
            Seu navegador não suporta vídeo HTML5.
        </video>
    );
};

export const VideoPlayerHeader = ({ show = false, videoUrl, fadeState, ...rest }) => {
    const videoStyles = {
        position: 'absolute',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: -1,
        filter: 'brightness(50%)',
        opacity: fadeState === 'fade-in' ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out'
    };

    return (
        <video
            controls={show}
            crossOrigin="anonymous"
            autoPlay
            loop
            width="100%"
            muted
            style={videoStyles}
            {...rest}
        >
            <source src={videoUrl} type="video/mp4" />
            Seu navegador não suporta vídeo HTML5.
        </video>
    );
};
