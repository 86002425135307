import { Center, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { IconGhostButton, YellowButton } from './button';
import { Loading } from './loading';

const formatCurrency = (price) => {
    return parseFloat(price).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
};

const toBuy = async (setLoading) => {
    setLoading(true); // Inicia o loading
    try {
        const response = await Api.post(`/purchaseorder`, { flowShoppingCartToPurchaseOrder: true });
        if (response && response.status === 201 && response.data.singleSaleUrl) {
            window.open(response.data.singleSaleUrl, '_blank'); // Abre a URL de pagamento em uma nova aba
            setLoading(false); // Para o loading após abrir a nova aba
        } else {
            console.error("No sale URL provided in the response");
            setLoading(false); // Para o loading em caso de erro
        }
    } catch (error) {
        console.error("Error processing the purchase:", error);
        setLoading(false); // Para o loading em caso de erro
    }
};



const CartItem = ({ id, name, price, image, by, address, onRemove }) => (
    <HStack spacing={5} justifyContent="space-between">
        <HStack spacing={3} h="full" align="center" justifyContent="flex-start">
            <Image src={image} alt={name} borderRadius="50%" boxSize="50px" />
            <VStack
                spacing={1}
                h="full"
                alignContent="flex-start"
                alignItems="flex-start"
                justifyContent="flex-start"
            >
                <Text fontSize="sm" color="whiteAlpha.750">
                    {name}
                </Text>
                <HStack spacing={3} h="full" align="flex-start" justifyContent="flex-start">
                    <Text fontSize="xs" color="whiteAlpha.500">
                        Por <Text as="span" color="whiteAlpha.750">{by}</Text>
                    </Text>
                    <Text fontSize="xs" color="whiteAlpha.500">
                        Em <Text as="span" color="whiteAlpha.750">{address}</Text>
                    </Text>
                </HStack>
            </VStack>
        </HStack>
        <HStack>
            <Text fontSize="sm">{formatCurrency(price)}</Text>
            <IconGhostButton color={colors.white75} onClick={() => onRemove(id)} icon={<FaTrash />} w="20px" />
        </HStack>
    </HStack>
);

export const Cart = ({ isOpen, onClose }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cartData, setCartData] = useState(null);
    const [cartItems, setCartItems] = useState([]);

    const removeItem = async (itemId) => {
        setLoading(true);
        try {
            await Api.delete(`/shoppingcart/video/${itemId}`);
            fetchCartData(); // Chama a função que atualiza o carrinho
        } catch (error) {
            console.error('Erro ao remover item do carrinho:', error);
            setLoading(false);
        }
    };

    const fetchCartData = async () => {
        setLoading(true);
        try {
            const response = await Api.get(`/shoppingcart/user/${user.id}`);
            const data = response.data;
            if (!data.totalItems) {
                setCartData(null);
                setCartItems([]);
            } else {
                // Extrair os dados principais do carrinho
                const newData = {
                    idShoppingCart: data.idShoppingCart,
                    totalItems: data.totalItems,
                    totalValue: data.totalValue
                };
                setCartData(newData);

                // Transformar os itens para o formato esperado
                const newItems = Object.keys(data.items).map(key => ({
                    id: data.items[key].idVideo,
                    name: data.items[key].titleScene,
                    price: parseFloat(data.items[key].price),
                    image: data.items[key].thumbMomentImgLink,
                    by: data.items[key].displayName,
                    address: `${data.items[key].city}, ${data.items[key].state}`
                }));
                setCartItems(newItems);
            }
            setLoading(false);
        } catch (error) {
            console.error('Erro ao obter dados do carrinho:', error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchCartData();
        }
    }, [isOpen, user?.id]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} borderRadius={10} size="lg" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={10}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75}>
                    Seu Carrinho ({cartData?.totalItems || 0})
                </Text>
                <Text fontSize="xs" color={colors.white50}>
                    Revise os detalhes do seu pedido
                </Text>

                <ModalBody my={10} p={0}>
                    {loading ? <Loading /> : <>
                        {cartItems.length > 0 ? (
                            <Stack spacing={10}>
                                {cartItems.map((item) => (
                                    <CartItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        price={item.price}
                                        image={item.image}
                                        by={item.by}
                                        address={item.address}
                                        onRemove={removeItem}
                                    />
                                ))}
                            </Stack>
                        ) : (
                            <Center>
                                <Text fontSize="md" color="whiteAlpha.750">
                                    E aí, o que está esperando para facilitar seu trabalho?
                                </Text>
                            </Center>
                        )}
                    </>}
                </ModalBody>

                <ModalFooter p={0} mt={5}>
                    {loading ? <></> : <>
                        {cartItems.length > 0 && (
                            <HStack spacing={2} justifyContent="space-between" w="full">
                                <VStack align="flex-start" spacing={0}>
                                    <Text fontSize="lg" fontWeight="bold" color={colors.white75}>
                                        {formatCurrency(cartData?.totalValue)}
                                    </Text>
                                    <Text fontSize="sm" color={colors.white50}>
                                        Total da compra com impostos
                                    </Text>
                                </VStack>
                                <YellowButton
                                    text="Finalizar compra"
                                    onClick={() => toBuy(setLoading)}
                                    isDisabled={loading} // Desabilita o botão enquanto está carregando
                                />
                            </HStack>
                        )}
                    </>}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
