import { Box, Container, Flex, HStack, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Slider, SliderFilledTrack, SliderTrack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronRight, FaCompress, FaExpand, FaPause, FaPlay } from 'react-icons/fa';
import heart from '../assets/icons/heart.png';
import heartpink from '../assets/icons/heartpink.png';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { GhostButton, YellowButton } from './button';
import { Cart } from './cart';
import { CustomTag } from './tag';

export const FastBuy = ({ video, isOpen, onClose }) => {
    const { user } = useAuth();
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [sliderValue, setSliderValue] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFavorite, setIsFavorite] = useState(video && video.isFavorite === 1);
    const [hasViewBeenRecorded, setHasViewBeenRecorded] = useState(false);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;
    const iconSrc = isFavorite ? heartpink : heart;
    const buttonText = isFavorite ? "Remover da Coleção" : "Salvar na Coleção";

    useEffect(() => {
        if (isOpen && video && video.id) {
            const timer = setTimeout(() => {
                if (!hasViewBeenRecorded) {
                    Api.post('/views', { idVideo: video.id })
                        .then(response => {
                            console.log("View recorded successfully:", response);
                            setHasViewBeenRecorded(true);
                        })
                        .catch(error => {
                            console.error("Failed to record view:", error);
                        });
                }
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isOpen, video?.id]);

    useEffect(() => {
        if (!isOpen) {
            setErrorBuy(null);
            setHasViewBeenRecorded(false); // Resetar o estado quando a modal for fechada
        }
    }, [isOpen]);

    const handleFavorite = () => {
        const newIsFavorite = !isFavorite; // Inverta o estado de favorito
        setIsFavorite(newIsFavorite); // Atualize o estado local

        const apiEndpoint = newIsFavorite ? '/favorites' : '/favorites/video/' + video.id;
        const apiMethod = newIsFavorite ? 'post' : 'delete';

        Api[apiMethod](apiEndpoint, { idVideo: video.id })
            .then(response => {
                video.isFavorite = newIsFavorite ? 1 : 0;
            })
            .catch(error => {
                setIsFavorite(!newIsFavorite);
            });
    };

    const handlePlayPause = (playing) => {
        setIsPlaying(playing);
    };

    const handleTimeUpdate = (e) => {
        e.stopPropagation();
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
            setSliderValue(videoRef.current.currentTime);
        }
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.mozRequestFullScreen) {
                videoRef.current.mozRequestFullScreen();
            } else if (videoRef.current.webkitRequestFullscreen) {
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) {
                videoRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    const formatTime = (time) => {
        if (typeof time !== 'number' || isNaN(time)) {
            return "";
        }

        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);

        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement));
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('MSFullscreenChange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
        };
    }, []);

    useEffect(() => {
        const fetchFavoriteStatus = async () => {
            if (video) {
                try {
                    const response = await Api.get(`/favorites/video/${video.id}`);
                    setIsFavorite(response.data !== null);
                } catch (error) {
                }
            }
        };

        if (isOpen) {
            fetchFavoriteStatus();
        }
    }, [video, isOpen]);

    useEffect(() => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.play().catch((error) => {
                    console.error("Error attempting to play the video:", error);
                });
            } else {
                videoRef.current.pause();
            }
        }
    }, [isPlaying]);

    // Resetar estados ao abrir/fechar a modal ou trocar de vídeo
    useEffect(() => {
        if (isOpen && video) {
            // Resetar estados
            setIsPlaying(true);
            setIsVideoReady(false);
            setRetryCount(0);
            setCurrentTime(0);
            setSliderValue(0);
            setHasViewBeenRecorded(false);

            // Resetar o vídeo
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
                videoRef.current.load();
            }
        } else {
            // Se a modal foi fechada
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
            }
        }
    }, [isOpen, video]);

    // Tentar reproduzir o vídeo quando estiver pronto
    useEffect(() => {
        if (isVideoReady && videoRef.current) {
            videoRef.current.currentTime = 0; // Reinicia o vídeo
            playVideoWithRetry();
        }
    }, [isVideoReady]);

    const playVideoWithRetry = () => {
        videoRef.current.play().catch((error) => {
            console.error("Error attempting to play the video:", error);
            if (retryCount < maxRetries) {
                setRetryCount(retryCount + 1);
                setTimeout(() => {
                    playVideoWithRetry();
                }, 1000);
            }
        });
    };

    const handleLoadedData = () => {
        setIsVideoReady(true);
    };

    const handleVideoError = (e) => {
        console.error("Video failed to load:", e);
        // Lógica adicional, se necessário
    };

    const [cartOpen, setCartOpen] = useState(false);
    const [cartUpdated, setCartUpdated] = useState(false);
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [errorBuy, setErrorBuy] = useState(null);

    const addToCartAndOpenModal = () => {
        setLoadingBuy(true);
        Api.post('/shoppingcart', { idVideo: video.id })
            .then(response => {
                if (response.data && response.data.id && response.data.id > 0) {
                    setCartUpdated(true);
                }
                setLoadingBuy(false);
            })
            .catch(error => {
                try {
                    setErrorBuy(error.response.data.message);
                } catch { }
                setLoadingBuy(false);
            });
    };

    useEffect(() => {
        if (cartUpdated) {
            setCartOpen(true);
            setCartUpdated(false);
        }
    }, [cartUpdated]);

    return (
        <>
            <Cart isOpen={cartOpen} onClose={() => setCartOpen(false)} />
            <Modal isOpen={isOpen} onClose={() => { onClose(); setHasViewBeenRecorded(false); }} borderRadius={10} size="5xl" isCentered>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
                <ModalContent backgroundColor={colors.background} p={0} borderRadius={10}>
                    <ModalCloseButton color="white" />
                    <ModalBody mt={{ base: 10, md: 0 }} p={0} borderRadius={10} height="100%">
                        <Container
                            maxW="container.xl"
                            m={0}
                            p={0}
                            display="flex"
                            flexDirection={{ base: 'column', md: 'row' }} // Mantém o breakpoint em md
                            justifyContent="space-between"
                            alignItems="stretch"
                            height="100%"
                        >
                            {/* VStack do Vídeo */}
                            <VStack
                                align="stretch"
                                position="relative"
                                flex={{ base: 'none', md: 20, lg: 6 }} // Flexível em md e acima
                                width={{ base: '100%', md: 'auto' }} // Largura responsiva
                            >
                                {/* Indicador de Carregamento */}
                                {!isVideoReady && (
                                    <Box
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        width="100%"
                                        height="100%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        backgroundColor="rgba(0, 0, 0, 0.5)"
                                        zIndex={1}
                                    >
                                        <Spinner size="xl" color="white" />
                                    </Box>
                                )}

                                {video && (
                                    <>
                                        <video
                                            ref={videoRef}
                                            autoPlay={true}
                                            width="100%"
                                            muted
                                            onTimeUpdate={handleTimeUpdate}
                                            onPause={() => handlePlayPause(false)}
                                            onPlay={() => handlePlayPause(true)}
                                            onLoadedData={handleLoadedData}
                                            onError={handleVideoError}
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                height: '100%',
                                                filter: 'brightness(90%)',
                                                borderTopLeftRadius: 10,
                                                borderBottomLeftRadius: 10,
                                            }}
                                        >
                                            <source src={video.videoUrl} type="video/mp4" />
                                            Seu navegador não suporta vídeo HTML5.
                                        </video>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 10,
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                zIndex: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                            }}
                                        >
                                            {/* Controles do vídeo */}
                                            <IconButton
                                                backgroundColor="transparent"
                                                onClick={() => handlePlayPause(!isPlaying)}
                                                icon={isPlaying ? <FaPause /> : <FaPlay />}
                                            />
                                            <Slider
                                                minW="200px"
                                                min={0}
                                                max={videoRef?.current?.duration || 0}
                                                colorScheme="yellow"
                                                value={sliderValue}
                                                onChange={(value) => {
                                                    if (videoRef.current) {
                                                        videoRef.current.currentTime = value;
                                                        setSliderValue(value);
                                                    }
                                                }}
                                                style={{ width: '80%' }}
                                            >
                                                <SliderTrack>
                                                    <SliderFilledTrack transition="all 0.25s ease-in-out" />
                                                </SliderTrack>
                                            </Slider>
                                            <Box display="flex" flexDirection="row">
                                                <Text>{formatTime(currentTime)}</Text>
                                                <Text as="span">&nbsp;/&nbsp;</Text>
                                                <Text>{formatTime(videoRef?.current?.duration)}</Text>
                                            </Box>
                                            <IconButton
                                                backgroundColor="transparent"
                                                ml={2}
                                                onClick={toggleFullScreen}
                                                icon={isFullScreen ? <FaCompress /> : <FaExpand />}
                                            />
                                        </div>
                                    </>
                                )}
                            </VStack>

                            {/* VStack do Conteúdo da Direita */}
                            <VStack
                                align="stretch"
                                mt={{ base: 4, md: 0 }} // Espaçamento no mobile
                                flex={{ base: 'none', md: 'auto' }}
                                width={{ base: '100%', md: 'auto' }} // Largura responsiva
                            >
                                <Flex
                                    maxW="md"
                                    borderRadius={10}
                                    overflow="hidden"
                                    bg={colors.background}
                                    color="white"
                                    width="100%" // Garante largura total no mobile
                                >
                                    <Box p="10" width="100%">
                                        {video && video.isNews && (
                                            <Text as="span" fontSize="xs" color={colors.white75}>
                                                Novidade!
                                            </Text>
                                        )}

                                        <Text as="span" fontSize="xs" color={colors.white75}>
                                            {video?.totalDownloads > 99 ? ' +99 Downloads' : ` ${video?.totalDownloads} Downloads`}
                                        </Text>

                                        <Box mt={5} display="flex" flexWrap="wrap" color={colors.white75} fontSize="xs">
                                            {video?.tags?.slice(0, 3).map((tag, index) => (
                                                <React.Fragment key={tag.id}>
                                                    {index > 0 && <Text mt={"5px"} ml={1} mr={1}><FaChevronRight size={7.5} /></Text>}
                                                    <Text>{tag.description}</Text>
                                                </React.Fragment>
                                            ))}
                                            {video?.tags && video.tags.length > 3 && (
                                                <CustomTag text={`+${video.tags.length - 3} Tags`} mt={-1} ml={4} />
                                            )}
                                        </Box>

                                        <Text mt={5} fontSize="3xl" fontWeight="semibold" lineHeight="35px">
                                            {video?.titleScene}
                                        </Text>

                                        <Box mt={5}>
                                            <Box as="span" fontSize="xl">
                                                R$ {video?.price}
                                            </Box>
                                        </Box>

                                        <Box d="flex" mt={5} display="flex" flexDirection="row" justifyContent="space-between">
                                            <Box d="flex" display="flex" flexDirection="row" maxW="60%">
                                                <Box py={2}>
                                                    <Image src={require("../assets/icons/map.png")} w="25px" />
                                                </Box>
                                                <Box display="flex" flexDirection="column" ml={2}>
                                                    <Box as="span" color={colors.white75} fontSize="xs">
                                                        Feito em
                                                    </Box>
                                                    <Box as="span" color={colors.white75} fontSize="sm">
                                                        {video?.city} - {video?.state}
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box d="flex" display="flex" flexDirection="row" ml={2} maxW="40%">
                                                <Box py={2}>
                                                    <Image src={require("../assets/icons/res.png")} w="20px" />
                                                </Box>
                                                <Box display="flex" flexDirection="column" ml={2}>
                                                    <Box as="span" color={colors.white75} fontSize="xs">
                                                        Resolução
                                                    </Box>
                                                    <Box as="span" color={colors.white75} fontSize="sm">
                                                        {video?.resolution}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <HStack mt={10} spacing="4" justifyContent="space-between">
                                            {!user && (
                                                <Text fontSize="xl" fontWeight="semibold" lineHeight="35px">
                                                    Entre para comprar
                                                </Text>
                                            )}
                                            {user && (
                                                <>
                                                    {errorBuy ? (
                                                        <Text fontSize="sm">{errorBuy}</Text>
                                                    ) : loadingBuy ? (
                                                        <Spinner
                                                            thickness="4px"
                                                            speed="0.65s"
                                                            emptyColor={colors.background}
                                                            color={colors.primary}
                                                            size="md"
                                                        />
                                                    ) : (
                                                        <YellowButton text="Comprar Video" onClick={addToCartAndOpenModal} />
                                                    )}
                                                    <GhostButton
                                                        color={colors.white75}
                                                        onClick={() => handleFavorite(video)}
                                                        icon={<Image src={iconSrc} w="20px" />}
                                                        text={buttonText}
                                                    />
                                                </>
                                            )}
                                        </HStack>
                                    </Box>
                                </Flex>
                            </VStack>
                        </Container>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
