import React from 'react';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    VStack,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td
} from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';

export const HowItWorks = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Como Funciona
                </Text>
                <ModalBody>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Bem-vindo à HausTri! Aqui, você pode monetizar seus vídeos curtos e receber <strong>50% do valor líquido da venda de cada vídeo</strong>.
                    </Text>
                    <Text fontSize="md" color={colors.white75} mb={4}>
                        Abaixo está uma simulação do que você pode ganhar:
                    </Text>
                    <Box overflowX="auto">
                        <Table variant="simple" size="sm">
                            <Thead>
                                <Tr>
                                    <Th color={colors.white75}>Valor do Vídeo</Th>
                                    <Th color={colors.white75}>Receita do Criador (PIX)*</Th>
                                    <Th color={colors.white75}>Receita do Criador (Cartão)**</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td color={colors.white75}>R$ 10</Td>
                                    <Td color={colors.white75}>R$ 3,76</Td>
                                    <Td color={colors.white75}>R$ 4,09</Td>
                                </Tr>
                                <Tr>
                                    <Td color={colors.white75}>R$ 20</Td>
                                    <Td color={colors.white75}>R$ 8,01</Td>
                                    <Td color={colors.white75}>R$ 8,17</Td>
                                </Tr>
                                <Tr>
                                    <Td color={colors.white75}>R$ 30</Td>
                                    <Td color={colors.white75}>R$ 12,26</Td>
                                    <Td color={colors.white75}>R$ 12,26</Td>
                                </Tr>
                                <Tr>
                                    <Td color={colors.white75}>R$ 40</Td>
                                    <Td color={colors.white75}>R$ 16,51</Td>
                                    <Td color={colors.white75}>R$ 16,34</Td>
                                </Tr>
                                <Tr>
                                    <Td color={colors.white75}>R$ 50</Td>
                                    <Td color={colors.white75}>R$ 20,76</Td>
                                    <Td color={colors.white75}>R$ 20,43</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Text fontSize="xs" color={colors.white75} mt={4}>
                        *Quando o cliente comprar via pix
                    </Text>
                    <Text fontSize="xs" color={colors.white75} mb={4}>
                        **Quando o cliente comprar via cartão
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Fechar" onClick={onClose} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
