import { Checkbox, CheckboxGroup, Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { colors } from '../styles/colors';

const LimitedCheckboxGroup = ({ value, setValues, options, maxSelections, disabled = false, resetCategorias, setResetCategorias }) => {

    useEffect(() => {
        console.log("resetCategorias => ", resetCategorias);
        if (resetCategorias) {
            // Limpa as seleções quando resetCategorias mudar
            setValues([]);
            setResetCategorias(false);
        }
    }, [resetCategorias]);

    const handleChange = (selectedValue) => {
        let newValues;
        if (value.includes(selectedValue)) {
            newValues = value.filter((item) => item !== selectedValue);
        } else if (value.length < maxSelections) {
            newValues = [...value, selectedValue];
        } else {
            return; // Não altera os valores se o limite máximo for atingido
        }
        setValues(newValues);
    };

    return (
        <CheckboxGroup value={value}>
            <Flex wrap="wrap" gap={3}>
                {options.map((option, index) => (
                    <Checkbox
                        key={index}
                        colorScheme='yellow'
                        value={option.value}
                        disabled={disabled}
                        onChange={() => handleChange(option.value)}
                        isChecked={value.includes(option.value)}
                    >
                        <Text fontSize="sm" color={colors.white75}>{option.label}</Text>
                    </Checkbox>
                ))}
            </Flex>
        </CheckboxGroup>
    );
};

export default LimitedCheckboxGroup;