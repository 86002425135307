import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from './colors';

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false, // Desativar o uso do modo de cor do sistema
};

const styles = {
    global: (props) => ({
        '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
        },
        body: {
            bg: mode(colors.background, colors.background)(props),
            color: mode(colors.white, colors.white)(props),
        },
    }),
};

const fonts = {
    heading: `'Plus Jakarta Sans', sans-serif`,
    body: `'Plus Jakarta Sans', sans-serif`,
};

const theme = extendTheme({ config, styles, colors, fonts });

export default theme;
