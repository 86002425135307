import { Alert, Avatar, Badge, Box, Container, Flex, Grid, HStack, Image, SimpleGrid, Switch, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importar o locale PT-BR
import React, { useEffect, useState } from 'react';
import { FaCheck, FaDollarSign, FaDownload } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';
import { colors } from '../styles/colors';
import { BlackButton, GhostButton, YellowButton } from './button';
import { CustomFormInput, CustomFormInputTag, CustomFormSelect } from './input';
import { Loading } from './loading';
import { ErrorModal } from './errorModal';

moment.locale('pt-br');

export const Profile = ({ optionInitial = 1, onClose }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  const [option, setOption] = useState(optionInitial);
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});

  const [success, setSuccess] = useState(null);

  async function loadOption(op) {
    setLoading(true);
    setSuccess(null);
    setErrors([]);
    setValues({});
    setOldValues({});

    const endpoints = {
      1: `users/${user.id}`,
      2: `userprofile/user/${user.id}`,
      3: `useraddress/user/${user.id}`,
      4: `userpaymentdata/user/${user.id}`,
      5: `userpreference/user/${user.id}`,
      7: `/users/AcquisitionsOrPurchases/${1}`,
      8: `/users/AcquisitionsOrPurchases/${2}`,
    };

    try {
      const endpoint = endpoints[op];
      if (endpoint) {
        const result = await Api.get(endpoint);
        const data = result.data;
        setOldValues(data);
        setValues(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadOption(option);
  }, [option])

  async function updateOption(op) {
    setLoading(true);
    setSuccess(null);

    // Inicializa oldValues como um objeto vazio se for undefined ou null
    const oldValuesSafe = oldValues || {};

    // Verifica se todos os valores em oldValuesSafe são strings vazias ou, no caso do op === 2, se as descrições estão preenchidas
    const areAllEmptyStrings = Object.values(oldValuesSafe).every(value => {
      if (Array.isArray(value) && op === 2) {
        return value.every(tag => tag.tagline.every(tl => tl.description !== ""));
      }
      return value === "";
    });

    const baseEndpoints = {
      1: `users/${user.id}`,
      2: `userprofile/user/${user.id}`,
      3: `useraddress/user/${user.id}`,
      4: `userpaymentdata/user/${user.id}`,
      5: `userpreference/user/${user.id}`,
    };

    const putEndpoints = {
      3: `useraddress/${oldValuesSafe.idUserAddress}`,
      4: `userpaymentdata/${oldValuesSafe.idUserPaymentData}`,
      5: `userpreference/${oldValuesSafe.idUserPreference}`,
    };

    const successMessages = {
      1: "Os dados do seu perfil foram atualizados com sucesso :)",
      2: "Os dados do perfil foram atualizados com sucesso :)",
      3: "Os dados de endereço foram atualizados com sucesso :)",
      4: "Os dados de pagamento foram atualizados com sucesso :)",
      5: "Os dados de preferência foram atualizados com sucesso :)",
    };

    try {
      // Define o método e o endpoint com base nos valores antigos e na operação (op)
      let method, endpoint;
      if (areAllEmptyStrings) {
        method = 'post';
        endpoint = baseEndpoints[op];
      } else {
        method = 'put';
        endpoint = putEndpoints[op] || baseEndpoints[op];
      }

      // Prepara os dados para a requisição
      const data = op === 4 ? { ...values, method: "PIX" } : values;

      // Faz a requisição à API
      await Api[method](endpoint, data);

      // Define a mensagem de sucesso e limpa os erros
      setSuccess(successMessages[op]);
      setErrors([]);
    } catch (error) {
      // Define os erros da resposta da API
      setErrors(error.response?.data?.errors || ['Erro desconhecido']);
    } finally {
      // Desativa o carregamento
      setLoading(false);
    }
  }


  function successMessage() {
    return <>
      {success != null && <>
        <VStack p={5} align="flex-start">
          <Alert status='success' borderRadius={20} colorScheme='yellow' backgroundColor={colors.green} color={colors.greenDark}>
            <FaCheck />
            <Text ml={2}>{success}</Text>
          </Alert>
        </VStack>
      </>
      }
    </>
  }

  const formatCurrency = (price) => {
    return parseFloat(price).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  async function openLink(link) {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  async function download(videos) {
    setLoading(true);
    setErrors([]);
    setErrorModalOpen(false);

    try {
      const downloadPromises = videos.map(async (x) => {
        try {
          const result = await Api.get("/video/download/" + x.idVideo);
          const url = result.data.url;

          const response = await fetch(url);
          const blob = await response.blob();

          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = x.idVideo + '.mp4';
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(a);
        } catch (error) {
          console.error("Erro ao baixar o vídeo: ", error);
          if (error.response) {
            setErrors(prevErrors => [...prevErrors, { msg: error.response.data.message || "Erro desconhecido", status: error.response.status }]);
          } else {
            setErrors(prevErrors => [...prevErrors, { msg: "Erro ao baixar o vídeo", status: 500 }]);
          }
          throw error;
        }
      });

      await Promise.all(downloadPromises);

      setSuccess("Os vídeos foram baixados.");
    } catch (error) {
      console.error("Erro ao baixar um ou mais vídeos: ", error);
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  }

  const hasMultipleRoles = (() => {
    const roles = user?.roles;
    if (Array.isArray(roles)) {
      return roles.length > 1;
    } else if (typeof roles === 'object') {
      return Object.values(roles).length > 1;
    }
    return false;
  })();

  return (
    <>
      <Container maxW="container.xl">
        <Flex minH="xl">
          <Box flex="1" borderRight={`1px solid ${colors.border}`} py={5} pr={5}>
            <VStack align="flex-start">
              <VStack p={4} align="flex-start">
                <Avatar name={user?.name} backgroundColor={colors.primary} mb={3} />
                <Text fontSize="xl" fontWeight="500">Olá, {user?.name}</Text>
                <Text fontSize="sm" fontWeight="300">Faça a gestão da sua conta por aqui</Text>
              </VStack>
              <VStack spacing={4} mb={5} align="flex-start">
                {[
                  { text: "Ver conta detalhada", index: 0, img: 1 },
                  { text: "Minhas Informações", index: 1, img: 1 },
                  { text: "Meu Perfil", index: 2, img: 2 },
                  { text: "Endereço", index: 3, img: 3 },
                  { text: "Pagamentos", index: 4, img: 4 },
                  { text: "Preferências", index: 5, img: 5 },
                  { text: "Compras", index: 7, img: 4 },
                  ...(Object.keys(user.roles).length > 1 ? [{ text: "Faturamento", index: 8, img: 4 },] : []),
                ].map(({ text, index, img }) => (
                  <GhostButton
                    disabled={loading}
                    key={index}
                    icon={<Image src={require("../assets/user/" + (option === index ? img + "_1" : img) + ".png")} w={5} h={5} />}
                    color={option === index ? colors.primary : colors.white}
                    text={text}
                    onClick={() => index === 0 ? (navigate("/conta"), onClose()) : (setLoading(true), setOption(index))}
                  />
                ))}
              </VStack>
            </VStack>
          </Box>

          <Box flex="3" py={5} pl={5}>
            {option === 1 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Dados de acesso</Text>
                    <Text fontSize="sm" fontWeight="300">Dados da sua conta</Text>
                  </VStack>
                  <YellowButton text="Salvar Informações" onClick={() => updateOption(option)} />
                </Flex>
              </VStack>

              {success != null && successMessage()}
              {loading ?
                <Loading />
                :
                <VStack p={5} align="flex-start">
                  <CustomFormInput id="fullName" label="Nome completo" errors={errors} values={values} setValues={setValues} />
                  <Grid templateColumns="2fr 3fr" gap={4} w="full">
                    <CustomFormInput id="phone" label="Telefone" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="email" label="E-mail" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                  <Grid templateColumns="2fr 3fr" gap={4} w="full">
                    <CustomFormInput id="identity" label="CPF/CNPJ" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                </VStack>
              }
            </>}

            {option === 2 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Perfil público</Text>
                    <Text fontSize="sm" fontWeight="300">Atualize informações de sua página principal</Text>
                  </VStack>
                  <YellowButton text="Salvar Informações" onClick={() => updateOption(option)} />
                </Flex>
              </VStack>

              {success != null && successMessage()}
              {loading ?
                <Loading />
                :
                <VStack p={5} align="flex-start">
                  <CustomFormInput id="displayName" label="Nome de Exibição" errors={errors} values={values} setValues={setValues} />
                  <CustomFormInputTag id="tagline" label="Tagline (Ex.: Freelancer, Videomaker, etc...)" errors={errors} values={values} setValues={setValues} />
                  <Grid templateColumns="4fr 1fr" gap={4} w="full">
                    <CustomFormInput id="city" disabled label="Cidade" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="state" disabled label="Estado" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                  <CustomFormInput id="url" disabled label="URL" errors={errors} values={values} setValues={setValues} />
                </VStack>
              }
            </>}

            {option === 3 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Endereço comercial</Text>
                    <Text fontSize="sm" fontWeight="300">Informações de endereço comercial</Text>
                  </VStack>
                  <YellowButton text="Salvar Informações" onClick={() => updateOption(option)} />
                </Flex>
              </VStack>

              {success != null && successMessage()}

              {loading ?
                <Loading />
                :
                <VStack p={5} align="flex-start">
                  <Grid templateColumns="4fr 2fr" gap={4} w="full">
                    <CustomFormInput id="street" label="Logradouro" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="number" label="Número" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                  <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
                    <CustomFormInput id="complement" label="Complemento" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="neighborhood" label="Bairro" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="cep" label="CEP" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                  <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
                    <CustomFormInput id="city" label="Cidade" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="state" label="Estado" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="country" label="País" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                </VStack>
              }
            </>}

            {option === 4 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Pagamento em conta</Text>
                    <Text fontSize="sm" fontWeight="300">Dados da sua conta corrente</Text>
                  </VStack>
                  <YellowButton text="Salvar Informações" onClick={() => updateOption(option)} />
                </Flex>
              </VStack>

              {success != null && successMessage()}
              {loading ?
                <Loading />
                :
                <VStack p={5} align="flex-start">
                  <Grid templateColumns="2fr 4fr" gap={4} w="full">
                    <CustomFormSelect id="typeOfPixKey" options={['CPF', 'CNPJ', 'E-mail', 'Chave Aleatória', 'Telefone']} label="Tipo de Chave" errors={errors} values={values} setValues={setValues} />
                    <CustomFormInput id="keyPix" label="Chave PIX" errors={errors} values={values} setValues={setValues} />
                  </Grid>
                </VStack>
              }
            </>}

            {option === 5 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Preferências</Text>
                    <Text fontSize="sm" fontWeight="300">Delimite sua busca para encontrar resultados</Text>
                  </VStack>
                  <YellowButton text="Salvar Informações" onClick={() => updateOption(option)} />
                </Flex>
              </VStack>

              {success != null && successMessage()}
              {loading ?
                <Loading />
                :
                <VStack p={5} align="flex-start">
                  <Text fontSize="md" fontWeight="400">Comunicados periódicos</Text>
                  <Grid templateColumns="5fr 1fr" gap={4} w="full" mb={3}>
                    <Text fontSize="sm" fontWeight="300" opacity={0.5}>Receber comunicações e informações por e-mail</Text>
                    <Switch colorScheme="yellow" isChecked={values?.periodicAnnouncements} onChange={(e) => setValues(prevValues => ({ ...prevValues, periodicAnnouncements: e.target.checked }))} />
                  </Grid>

                  <Text fontSize="md" fontWeight="400">Comunicados sobre ativos</Text>
                  <Grid templateColumns="5fr 1fr" gap={4} w="full" mb={3}>
                    <Text fontSize="sm" fontWeight="300" opacity={0.5}>Receber comunicados via e-mail a cada compra ou download</Text>
                    <Switch colorScheme="yellow" isChecked={values?.communicationsAboutAssets} onChange={(e) => setValues(prevValues => ({ ...prevValues, communicationsAboutAssets: e.target.checked }))} />
                  </Grid>

                  <Text fontSize="md" fontWeight="400">Comunicados sobre transações</Text>
                  <Grid templateColumns="5fr 1fr" gap={4} w="full" mb={3}>
                    <Text fontSize="sm" fontWeight="300" opacity={0.5}>Receber comunicados via e-mail a cada saque ou nova venda</Text>
                    <Switch colorScheme="yellow" isChecked={values?.communicationsAboutTransactions} onChange={(e) => setValues(prevValues => ({ ...prevValues, communicationsAboutTransactions: e.target.checked }))} />
                  </Grid>
                </VStack>
              }
            </>}

            {option === 7 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Compras</Text>
                    <Text fontSize="sm" fontWeight="300">Histórico de compras e aquisições</Text>
                  </VStack>
                </Flex>
              </VStack>

              {loading ?
                <Loading />
                :
                <>
                  <HStack px={5} align="flex-start">
                    <YellowButton text="Atualizar" onClick={() => loadOption(7)} />
                    <GhostButton text="Ir para galeria" onClick={() => { navigate("/conta/2"); onClose(); }} />
                  </HStack>
                  <VStack p={5} align="flex-start" w="full">
                    <SimpleGrid columns={7} spacing={3} mt={5} alignItems="center" w="full">
                      <Box><Text fontSize="sm" color={colors.white75}>Descrição</Text></Box>
                      <Box><Text fontSize="sm" color={colors.white75}>Status</Text></Box>
                      <Box><Text fontSize="sm" color={colors.white75}>Transação/ID</Text></Box>
                      <Box><Text fontSize="sm" color={colors.white75}>Data</Text></Box>
                      <Box><Text fontSize="sm" color={colors.white75}>Tipo</Text></Box>
                      <Box><Text fontSize="sm" color={colors.white75}>Valor</Text></Box>
                      <Box><Text fontSize="sm" color={colors.white75}>Ações</Text></Box>
                    </SimpleGrid>

                    {values && values.length > 0 && Array.isArray(values) ? (
                      values.map((dataAcquisitionsOrPurchases, index) => (
                        <React.Fragment key={index}>
                          <SimpleGrid columns={7} spacing={3} mt={5} alignItems="center" w="full">
                            <Box>
                              <Text fontSize="sm" color={colors.white50}>
                                {dataAcquisitionsOrPurchases.videos
                                  .map((video, index) => index < 2 ? video.idVideo : null)
                                  .filter(v => v)
                                  .join(', ') + (dataAcquisitionsOrPurchases.videos.length > 2 ? ` +${dataAcquisitionsOrPurchases.videos.length - 2} itens` : '')}
                              </Text>
                            </Box>
                            <Box><Badge>{dataAcquisitionsOrPurchases.status}</Badge></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{dataAcquisitionsOrPurchases.idPurchaseOrder}</Text></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{moment(dataAcquisitionsOrPurchases.transactionDateRequest).format('DD/MM/YYYY')}</Text></Box>
                            <Box><Badge>{dataAcquisitionsOrPurchases.paymentTypeDescription}</Badge></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{formatCurrency(dataAcquisitionsOrPurchases.totalPurchase)}</Text></Box>
                            <Box display="flex" justifyContent="flex-start">
                              {dataAcquisitionsOrPurchases.status === "Pendente" ? (
                                <BlackButton text="Pagar" icon={<FaDollarSign />} onClick={() => openLink(dataAcquisitionsOrPurchases.singleSaleUrl)} />
                              ) : (
                                <YellowButton text="Download" onClick={() => download(dataAcquisitionsOrPurchases.videos)} />
                              )}
                            </Box>
                          </SimpleGrid>
                        </React.Fragment>
                      ))
                    ) : (
                      <Text mt={2}>Nenhum conteúdo disponível</Text>
                    )}
                  </VStack>
                </>
              }
            </>}

            {hasMultipleRoles && option === 8 && <>
              <VStack p={5} align="flex-start">
                <Flex justify="space-between" align="flex-start" w="full" mb={4}>
                  <VStack align="flex-start">
                    <Text fontSize="lg" fontWeight="500">Faturamento</Text>
                    <Text fontSize="sm" fontWeight="300">Relatório das sua vendas realizadas</Text>
                  </VStack>
                </Flex>
              </VStack>

              {loading ?
                <Loading />
                :
                <>
                  <VStack px={5} align="flex-start">
                    <YellowButton text="Atualizar" onClick={() => loadOption(8)} />
                  </VStack>
                  <VStack p={5} align="flex-start" w="full">
                    <SimpleGrid columns={6} spacing={3} mt={5} alignItems="center" w="full">
                      <Box><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Descrição</Text></Box>
                      <Box><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Status</Text></Box>
                      <Box><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Transação/ID</Text></Box>
                      <Box><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Data</Text></Box>
                      <Box><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Tipo</Text></Box>
                      <Box><Text fontSize="sm" fontWeight="bold" color={colors.white75}>Valor</Text></Box>
                    </SimpleGrid>

                    {values && values.length > 0 && Array.isArray(values) ? (
                      values.map((dataAcquisitionsOrPurchases, index) => (
                        <React.Fragment key={index}>
                          <SimpleGrid columns={6} spacing={3} mt={5} alignItems="center" w="full">
                            <Box>
                              <Text fontSize="sm" color={colors.white50}>
                                {dataAcquisitionsOrPurchases.videos
                                  .map((video, index) => index < 2 ? video.idVideo : null)
                                  .filter(v => v)
                                  .join(', ') + (dataAcquisitionsOrPurchases.videos.length > 2 ? ` +${dataAcquisitionsOrPurchases.videos.length - 2} itens` : '')}
                              </Text>
                            </Box>
                            <Box><Badge colorScheme="green">{dataAcquisitionsOrPurchases.status}</Badge></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{dataAcquisitionsOrPurchases.idPurchaseOrder}</Text></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{moment(dataAcquisitionsOrPurchases.transactionDateRequest).format('DD MMMM YYYY')}</Text></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{dataAcquisitionsOrPurchases.paymentTypeDescription}</Text></Box>
                            <Box><Text fontSize="sm" color={colors.white50}>{formatCurrency(dataAcquisitionsOrPurchases.totalPurchase)}</Text></Box>
                          </SimpleGrid>
                        </React.Fragment>
                      ))
                    ) : (
                      <Text mt={2}>Nenhum conteúdo disponível</Text>
                    )}
                  </VStack>
                </>}
            </>}

          </Box>
        </Flex>
      </Container >
      <ErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        errors={errors}
      />
    </>
  );
};
