import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

export default createGlobalStyle`
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f120;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colors.primary}30;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.primary}30;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f120;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colors.primary};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.primary};
    }

    body {
        background-color: #000;
        color: ${colors.white};
        margin: 0px !important;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    * {
        font-family: 'Plus Jakarta Sans', sans-serif;
        color: inherit;
    }

    html {
        font-size: 14px;
        position: relative;
        min-height: 100%;
    }

    .rounded-none {
        border-radius: 0px;
    }

    .rounded-r-none {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .rounded-l-none {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .moving-words-container {
        width: 100%;
        overflow: hidden;
    }
      
    .moving-word {
        display: inline-block;
        white-space: nowrap;
        animation: moveRightLeft 5s linear;
    }
      
    @keyframes moveRightLeft {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
    }

    .leaflet-right, .leaflet-control-zoom {
        display: none !important;
    }

    .leaflet-div-icon {
        background: none;
        border: none;
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    .fade-exit {
        opacity: 1;
    }
    .fade-exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }
`;
