import React, { useState } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Textarea, Text, VStack, Box } from '@chakra-ui/react';
import { YellowButton } from './button';
import { colors } from '../styles/colors';
import { useAuth } from '../hooks/auth';
import Api from '../services/api';

export const ProblemReport = ({ isOpen, onClose }) => {
    const { user } = useAuth();
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const sendReport = async () => {
        if (message.trim().length === 0) {
            setErrorMessage('Por favor, preencha o campo para enviar um relato.');
            return;
        } else if (message.trim().length < 50) {
            setErrorMessage('Por favor, seja mais específico e detalhista. Descreva o problema com pelo menos 50 caracteres.');
            return;
        }

        try {
            const payload = {
                from: user?.email,  // E-mail do usuário logado
                message: message  // Mensagem escrita pelo usuário no textarea
            };

            const response = await Api.post('/sendreport', payload);
            console.log(response.data);
            setMessage('');
            setErrorMessage('');
            onClose(); // Fechar modal após enviar
        } catch (error) {
            console.error('Error sending problem report:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
            <ModalContent backgroundColor={colors.background} p={6}>
                <ModalCloseButton color="white" />
                <Text fontSize="xl" color={colors.white75} mb={4}>
                    Relatar um Problema
                </Text>
                <ModalBody>
                    <Textarea
                        placeholder="Descreva o problema encontrado..."
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                            setErrorMessage(''); // Limpar mensagem de erro ao começar a digitar
                        }}
                        size="sm"
                    />
                    {errorMessage && (
                        <Text mt={2} color="red.500" fontSize="sm">
                            {errorMessage}
                        </Text>
                    )}
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w="full">
                        <YellowButton text="Enviar Relato" onClick={sendReport} />
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
