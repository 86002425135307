import React, { useEffect, useRef } from 'react';
import { colors } from '../styles/colors';

const HorizontalScrollingText = ({ texts, scrollDirection = 'leftToRight' }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    let requestId;

    const move = () => {
      if (!container || !content) return;

      const containerWidth = container.getBoundingClientRect().width;
      const contentWidth = content.getBoundingClientRect().width;
      const scrollAmount = contentWidth - containerWidth;
      const speed = 1; // Define a velocidade do scroll (um número menor é mais lento)
      const interval = 30; // Define o intervalo de tempo entre os movimentos do scroll

      if (scrollDirection === 'leftToRight') {
        requestId = setInterval(() => {
          container.scrollLeft += speed;
          if (container.scrollLeft >= scrollAmount) {
            container.scrollLeft = 0;
          }
        }, interval);
      } else {
        requestId = setInterval(() => {
          container.scrollLeft -= speed;
          if (container.scrollLeft <= 0) {
            container.scrollLeft = scrollAmount;
          }
        }, interval);
      }

      return () => clearInterval(requestId);
    };

    move();

    return () => clearInterval(requestId);
  }, [scrollDirection]);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        overflow: 'hidden',
        marginBottom: 20,
        marginTop: 20,
        whiteSpace: 'nowrap',
        position: 'relative',
        padding: '10px',
        boxSizing: 'border-box',
      }}
    >
      <div ref={contentRef} style={{ display: 'inline-block' }}>
        {texts?.map((text, index) => (
          <span key={index} style={{ margin: '10px', borderWidth: 2, borderColor: colors.whiteTransparent, borderRadius: 50, padding: "10px 25px" }}>
            {text.description}
          </span>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScrollingText;
